import React from "react";
import { Card, CardBody } from "reactstrap";
import TableContainerPagination from "components/Common/TableContainerPagination";
import {
    OrderId, Total, StartDate, Owner, RentalType, HangarOwnerTitle, PlaneOwnerTitle, CancelAtEnd, Date, EndDate
} from "../../pages/Dashboard-Orders/LatestRequestsCol";
import { Button } from "reactstrap";
import DefaultLoading from "common/loading";

const UpcomingCheckIns = ({
    data,
    loading,
    pagination,
    fetchData,
    navigateToDetails,
    className
}) => {
    // Define columns for check-ins
    const columns = [
        {
            Header: "Reservation Id",
            accessor: "id",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
                return <OrderId {...cellProps} />;
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            disableFilters: true,
            Cell: cellProps => {
                return <Date {...cellProps} />;
            },
        },
        {
            Header: "Hangar Owner",
            accessor: "hangar_owner.full_name",
            disableFilters: true,
            Cell: cellProps => {
                return <HangarOwnerTitle {...cellProps} />;
            },
        },
        {
            Header: "Plane Owner",
            accessor: "user_id.full_name",
            disableFilters: true,
            Cell: cellProps => {
                return <PlaneOwnerTitle {...cellProps} />;
            },
        },
        {
            Header: "Rental Type",
            accessor: "rental_type",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
                return <RentalType {...cellProps} />;
            },
        },
        {
            Header: "Status",
            accessor: "order_status",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
                return <Owner {...cellProps} />;
            },
        },
        {
            Header: "Amount",
            accessor: "reservation_amount",
            Cell: cellProps => {
                return <Total {...cellProps} />;
            },
            disableFilters: true,
        },
        {
            Header: "Check In",
            accessor: "check_in",
            Cell: cellProps => {
                return <StartDate {...cellProps} />;
            },
            disableFilters: true,
        },
        {
            Header: "Check Out",
            accessor: "check_out",
            Cell: cellProps => {
                return <EndDate {...cellProps} />;
            },
            disableFilters: true,
        },
        {
            Header: "Ending on Check Out",
            accessor: "cancel_at_end",
            Cell: cellProps => {
                return <CancelAtEnd {...cellProps} />;
            },
            disableFilters: true,
        },
        {
            Header: "Total",
            accessor: "total_Amount",
            Cell: cellProps => {
                return <Total {...cellProps} />;
            },
            disableFilters: true,

        },
        {
            Header: "View Details",
            disableFilters: true,
            accessor: row => {
                return (
                    <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded"
                        onClick={() => navigateToDetails(row.id)}
                    >
                        View Details
                    </Button>
                );
            },
        },
    ];

    return (
        <Card className={className || ""}>
            <CardBody className="position-relative" style={{ minHeight: "200px" }}>
                <div className="mb-3">
                    <h5 className="font-size-15">
                        <i className="bx bx-calendar-event me-1 text-primary"></i>
                        Upcoming Check-Ins (Next 3 Days)
                    </h5>
                </div>

                {loading && <DefaultLoading style={{ position: "absolute", width: "100%", height: "100%" }} />}

                <TableContainerPagination
                    columns={columns}
                    data={data}
                    isSortBy={false}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilter={false}
                    customPageSize={10}
                    pagination={pagination}
                    refetchData={fetchData}
                    className="custom-header-css"
                    rowClass="cursor-auto-imp"
                    placeholder="Search aircraft or tail..."
                />
            </CardBody>
        </Card>
    );
};

export default UpcomingCheckIns;