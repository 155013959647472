import DefaultLoading, { Spinner } from "common/loading"
import FieldError from "components/Common/FieldError"
import TextEditJodit from "components/Common/TextEditJodit"
import { TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import React, { useEffect, useRef, useState } from "react"
import { useHistory, } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Container } from "reactstrap"


const DEFAULT_FIELDS = {
  slug: '',
  title: '',
  description: '',
  seo_title: '',
  seo_description: "",
  faqs: []

}
const PageMetaForm = () => {
  document.title = "Create Page Meta | HangarDirect"
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [pageMetaData, setPageMetaData] = useState(DEFAULT_FIELDS)
  console.log('pageMetaData: ', pageMetaData);
  const [errors, setErrors] = useState({})
  const editorRef = useRef(null);


  useEffect(() => {
    if (history.location.search)
      fetchPageMeta()
  }, [])


  const sanitizeHTML = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Check if content is empty or contains only whitespace
    if (tempDiv.textContent.trim() === '') {
      return '';
    }

    // Optional: Clean up empty paragraphs
    const paragraphs = tempDiv.querySelectorAll('p');
    paragraphs.forEach(p => {
      if (p.innerHTML === '<br>' || p.textContent.trim() === '') {
        p.remove();
      }
    });

    return tempDiv.innerHTML;
  };
  const fetchPageMeta = async () => {
    setLoading(true)

    const id = history.location.search.replace("?id=", "");
    try {
      let { data, error } = await supabase
        .from(TABLES.PAGE_META)
        .select("*")
        .eq("id", id)
        .single();
      if (error) throw error;
      if (data) {
        setLoading(false)

        setPageMetaData(data);
      }
    } catch (error) {
      setLoading(false)

      console.log("error: ", error.message);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target
    setPageMetaData({ ...pageMetaData, [name]: value });
    setErrors({ ...errors, [name]: "" })
  }

  const validation = () => {
    let err = {};

    if (!pageMetaData?.title) {
      err.title = "Please enter title";
      const elem = document.getElementById("titleinput");
      elem.scrollIntoView({ behavior: "smooth" });
    }
    if (!pageMetaData?.description) {
      err.description = "Please enter description";
    }
    if (!pageMetaData?.slug) {
      err.slug = "Please enter slug";
    }
    if (!pageMetaData?.seo_title) {
      err.seo_title = "Please enter SEO title";
    }

    if (!pageMetaData?.seo_description) {
      err.seo_description = "Please enter SEO description";
    }

    if (pageMetaData.faqs && pageMetaData.faqs.length > 0) {
      pageMetaData.faqs.forEach((faq, index) => {
        if (faq.question && !faq.answer) {
          err[`faqs[${index}].answer`] = "Please enter an answer for the question";
        }
        if (!faq.question && faq.answer) {
          err[`faqs[${index}].question`] = "Please enter a question for the answer";
        }
      });
    }
    if (Object.keys(err).length) {
      setErrors(err);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    const id = history.location.search.replace("?id=", "");

    e.preventDefault();
    if (!validation()) return;
    setErrors({})
    setLoading(true)
    try {

      const { data, error } = id ? await supabase.from(TABLES.PAGE_META).upsert(pageMetaData).select() : await supabase.from(TABLES.PAGE_META).insert(pageMetaData).select();

      if (error) throw error;
      if (data) {
        setPageMetaData(DEFAULT_FIELDS)
        toast.success(id ? "Updated successfully" : "Submitted successfully");
        history.push("/page-meta");
      }

    } catch (error) {
      toast.error(error?.message);
      console.log("error: ", error);
    }
    setLoading(false)
  };


  const handleFAQChange = (e, index, field) => {
    const newFaqs = [...pageMetaData.faqs];
    newFaqs[index][field] = e.target.value;
    setPageMetaData({ ...pageMetaData, faqs: newFaqs });
  };
  const handleDeleteFAQ = (index) => {
    const newFaqs = pageMetaData.faqs.filter((_, i) => i !== index);
    setPageMetaData({ ...pageMetaData, faqs: newFaqs });
  };
  return (
    <React.Fragment>

      <div id="titleinput" />
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) :
            <div className="mx- pb-4 shadow-md">
              <div className="d-flex justify-content-between ">
                <h4 className="mx-9 mb-3 text-[#252F40]  text-lg font-semibold">
                  Create Page Meta
                </h4>
              </div>
              <div style={{ background: "#fff" }} className="p-4 rounded shadow-lg">

                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="sluginput"
                      className="font-weight-bold mb-0 form-label fs-5"
                    >
                      Slug <span className="text-info">*</span>
                    </label>
                    <input
                      style={{
                        height: "48px",
                        borderRadius: "8px",
                        backgroundColor: "#F8F8F8"
                      }}
                      maxLength={60}
                      type="text"
                      className="form-control"
                      name={"slug"}
                      value={pageMetaData.slug}
                      id="sluginput"
                      placeholder="Enter slug"
                      onChange={handleChange}
                    />
                    <div className="d-flex justify-content-between text-primary  w-full text-right">
                      <FieldError text={errors?.slug} />
                      Characters remaining {60 - pageMetaData.slug.length}
                    </div>
                  </div>
                  <div className="col">
                    <label
                      htmlFor="titleinput"
                      className="font-weight-bold mb-0 form-label fs-5"
                    >
                      Title <span className="text-info">*</span>
                    </label>
                    <input
                      style={{
                        height: "48px",
                        borderRadius: "8px",
                        backgroundColor: "#F8F8F8"
                      }}
                      maxLength={60}
                      type="text"
                      className="form-control"
                      name={"title"}
                      value={pageMetaData.title}
                      id="titleinput"
                      placeholder="Enter article title"
                      onChange={handleChange}
                    />
                    <div className="d-flex justify-content-between text-primary  w-full text-right">
                      <FieldError text={errors?.title} />
                      Characters remaining {60 - pageMetaData.title.length}
                    </div>
                  </div>
                </div>


                {/* description  */}
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <label
                      htmlFor="titleinput"
                      className="font-weight-bold mb-0 form-label fs-5"
                    >
                      Description <span className="text-info">*</span>
                    </label>

                    <TextEditJodit
                      key={pageMetaData.id || 'new-article'}
                      refer={editorRef}
                      onBlur={(newContent) => {
                        // Sanitize the content before saving
                        const sanitizedValue = sanitizeHTML(newContent);

                        setPageMetaData({
                          ...pageMetaData,
                          description: sanitizedValue // Use sanitized value here
                        });
                        setErrors({
                          ...errors,
                          description: ""
                        });
                      }}
                      value={pageMetaData.description}
                      maxChar={2500}
                      error={errors.description}
                    />
                    <FieldError text={errors?.description} />
                  </div>
                </div>

                {/* seo title description  */}
                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="soe_title_input"
                      className="font-weight-bold mb-0 form-label fs-5"
                    >
                      Seo Title <span className="text-info">*</span>
                    </label>
                    <input
                      style={{
                        height: "48px",
                        borderRadius: "8px",
                        backgroundColor: "#F8F8F8"
                      }}
                      maxLength={60}
                      type="text"
                      className="form-control"
                      name={"seo_title"}
                      value={pageMetaData.seo_title}
                      id="soe_title_input"
                      placeholder="Enter Seo Title"
                      onChange={handleChange}
                    />
                    <div className="d-flex justify-content-between text-primary  w-full text-right">
                      <FieldError text={errors?.seo_title} />
                      Characters remaining {60 - pageMetaData.seo_title.length}
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="seo_description_input"
                      className="font-weight-bold mb-0 form-label fs-5"
                    >
                      Seo Description <span className="text-info">*</span>
                    </label>
                    <textarea
                      style={{
                        height: "148px",
                        borderRadius: "8px",
                        backgroundColor: "#F8F8F8"
                      }}
                      maxLength={160}
                      type="textarea"
                      className="form-control"
                      name={"seo_description"}
                      value={pageMetaData.seo_description}
                      id="seo_description_input"
                      placeholder="Enter Seo Description"
                      onChange={handleChange}
                    />
                    <div className="d-flex justify-content-between text-primary  w-full text-right">
                      <FieldError text={errors?.seo_description} />
                      Characters remaining {160 - pageMetaData.seo_description.length}
                    </div>
                  </div>
                </div>

                {/* FAQs */}
                <div>
                  {pageMetaData.faqs.map((faq, index) => (
                    <div className="border border-primary p-3 mt-3 rounded" key={index}>
                      <div className="row" >
                        <div className="co">
                          <label htmlFor={`question-${index}`} className="font-weight-bold mb-0 form-label fs-5">
                            Question <span className="text-info">*</span>
                          </label>
                          <input
                            style={{
                              height: "48px",
                              borderRadius: "8px",
                              backgroundColor: "#F8F8F8"
                            }}
                            maxLength={100}
                            type="text"
                            className="form-control"
                            name={`faqs[${index}].question`}
                            value={faq.question}
                            id={`question-${index}`}
                            placeholder="Enter question"
                            onChange={(e) => handleFAQChange(e, index, 'question')}
                          />
                          <div className="d-flex justify-content-between text-primary w-full text-right">
                            <FieldError text={errors?.[`faqs[${index}].question`]} />
                          </div>
                        </div>
                        <div className="col">
                          <label htmlFor={`answer-${index}`} className="font-weight-bold mb-0 form-label fs-5">
                            Answer <span className="text-info">*</span>
                          </label>
                          <TextEditJodit
                            key={faq.id || `faq-${index}`}
                            refer={editorRef}
                            onBlur={(newContent) => {
                              // Sanitize empty HTML content
                              const sanitizedValue = sanitizeHTML(newContent);

                              const syntheticEvent = {
                                target: {
                                  name: `faqs[${index}].answer`,
                                  value: sanitizedValue,
                                },
                              };

                              handleFAQChange(syntheticEvent, index, 'answer');
                              setErrors(prev => ({
                                ...prev,
                                [`faqs[${index}].answer`]: "",
                              }));
                            }}
                            value={faq.answer}
                            maxChar={200}
                            error={errors[`faqs[${index}].answer`]}
                            style={{
                              height: "48px",
                              borderRadius: "8px",
                              backgroundColor: "#F8F8F8",
                            }}
                            className="form-control"
                            placeholder="Enter answer"
                          />
                          <div className="d-flex justify-content-between text-primary w-full text-right">
                            <FieldError text={errors?.[`faqs[${index}].answer`]} />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2" style={{
                        marginLeft: "auto",
                        width: 'fit-content'
                      }}>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleDeleteFAQ(index)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6" width={20}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                  <div style={{
                    marginLeft: 'auto',
                    width: 'fit-content'
                  }}>
                    <button
                      type="button"
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        setPageMetaData({
                          ...pageMetaData,
                          faqs: [...pageMetaData.faqs, { question: '', answer: '' }]
                        });
                      }}
                    >
                      Add FAQ
                    </button>
                  </div>
                </div>
                <div style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: 'fit-content'
                }}>
                  <Button
                    disabled={loading}
                    type="button"
                    color="info"
                    className="btn-md btn-rounded-3 px-4 py-2 "
                    onClick={handleSubmit}
                  >
                    {loading ? <Spinner hT={"20px"} wT={"20px"} /> : pageMetaData?.id ? "Update" : "Submit"}
                  </Button>
                </div>
              </div>
            </div>
          }
        </Container >
      </div>
    </React.Fragment >
  )
}

export default PageMetaForm
