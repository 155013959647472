import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
} from "reactstrap"
import { Phone } from "./contactlistCol"
//redux
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import Swal from "sweetalert2"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"
import { DATE_FORMATS, TABLES } from "pages/Utility/constants"
import { toast } from "react-toastify"
import fetchPaginatedData from "utils/fetchPaginatedData"
import TableContainerPagination from "components/Common/TableContainerPagination"

const SWAL_DEFAULT_PROPS = {
  title: "Action",
  showCancelButton: true,
  confirmButtonText: "Yes",
  confirmButtonColor: "red",
  cancelButtonText: "No",
  showCloseButton: true,
  buttonsStyling: "h-5",
}

const UsersListPage = props => {
  document.title = "Users List | HangarDirect";
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async (parameter) => {
    try {
      setLoading(true)

      let defaultFilters = [
        { column: 'email', operator: 'not.eq', value: 'null' },
        { column: 'full_name', operator: 'not.eq', value: 'null' },
      ]

      const filter = parameter?.filter;
      let orConditions = null;

      if (filter?.length) {
        orConditions =
          `full_name.ilike.%${filter}%,` +
          `email.ilike.%${filter}%`
      }

      const result = await fetchPaginatedData({
        table: 'profile',
        select: 'id,full_name,email,is_active,referral_enabled,user_type,created_at',
        page: parameter?.page || pagination?.currentPage || 1,
        pageSize: parameter?.pageSize || pagination?.pageSize,
        orderBy: 'created_at',
        ascending: false,
        filters: defaultFilters,
        orConditions: orConditions
      });

      setData(result.data);
      setPagination(result.pagination);

    } catch (error) {
      toast.error(error.message)
      console.error("Error fetching user data:", error.message);
    } finally {
      setLoading(false);
    }
  }

  const updateUser = async (id, body, checkName) => {

    Swal.fire({
      html:
        checkName?.includes("Referral") ? body?.referral_enabled ? "Enable Referral?" : "Disable Referral?" : body?.user_type === "USER"
          ? "Delete this user from admin post"
          : "Make this user admin",
      ...SWAL_DEFAULT_PROPS
    }).then(async result => {
      if (result.isConfirmed) {
        await supabase
          .from("profile")
          .update(body)
          .eq("id", id)
        getUserData()
      } else {
        const input = document.getElementById(checkName);
        input.checked = !input.checked;
        Swal.fire("Changes are not saved", "", "info")
      }
    })
  }
  
  const toggleActiveUser = async (userId, isActive) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the user's active status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true
    });

    if (!result.isConfirmed) {
      Swal.fire("Cancelled", "No changes were made.", "info");
      return;
    }

    try {
      const { error } = await supabase.rpc("toggle_user_block", {
        user_id: userId,
        active: isActive
      });

      if (error) {
        throw new Error(error.message);
      }

      Swal.fire({
        title: "Success!",
        text: "User status has been updated.",
        icon: "success",
        confirmButtonText: "OK"
      });
      getUserData()

    } catch (err) {
      console.error("Error:", err);

      Swal.fire({
        title: "Error",
        text: "Failed to update user status. Please try again.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };


  const columns = [
    {
      Header: "ID",
      disableFilters: false,
      accessor: (row, index) => {
        // Calculate the actual index based on pagination
        const pageSize = pagination?.pageSize || 10;
        const currentPage = pagination?.currentPage || 1;
        const globalIndex = (currentPage - 1) * pageSize + index + 1;
        return "#" + globalIndex;
      },
    },
    {
      Header: "Name",
      disableFilters: true,
      filterable: true,
      accessor: ({ full_name, img_url }) => {

        const handleError = (e) => {
          e.target.src = "/images/default_user.svg"; // Replace with your placeholder image URL
          e.target.onerror = null; // Prevent infinite loop in case fallback also fails
        };

        return (
          <div className="d-flex align-items-center" >

            <img
              className="rounded-circle avatar-xs"
              src={getImageUrl(img_url)}
              onError={handleError}
            />

            <span
              style={{ marginLeft: "10px" }}
            >{full_name}</span>
          </div>
        )
      },
    },

    {
      Header: "Email",
      filterable: true,
      accessor: row => {
        const copyToClipboard = async (txt) => {
          toast.dismiss()
          try {
            await navigator.clipboard.writeText(txt);
            toast.success('Email copied to clipboard!');
          } catch (err) {
            toast.error('Failed to copy email!');
          }
        };

        return (
          <div

          >
            {row?.email}
            <i onClick={() => copyToClipboard(row?.email)} style={{ cursor: "pointer", padding: "5px" }} className="fa fa-regular xs fa-copy" />
          </div>
        )
      },
    },

    {
      Header: "Phone Number",
      accessor: "phone",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <Phone {...cellProps} />
      },
    },
    {
      Header: "Join Date",
      filterable: true,
      disableFilters: true,
      accessor: "created_at",

      Cell: cellProps => {
        return moment(cellProps.row.original.created_at).format(
          DATE_FORMATS.CREATED_AT
        )
      },
    },
    {
      Header: "Status",
      filterable: true,
      disableFilters: true,
      accessor: row => {
        return (
          <FormGroup switch style={{ width: 'px' }}>
            <Input
              type="switch"
              fill="red"
              id="checkReferral"
              color="secondary"
              style={{ width: "35px", height: "18px", margin: "auto", cursor: "pointer" }}
              checked={row.is_active}
              onClick={() => {
                toggleActiveUser(
                  row.id,
                  !row.is_active,

                )
              }}
            />
          </FormGroup>
        )
      },
    },

    {
      Header: "Make admin",
      filterable: true,
      accessor: row => {
        return (
          <FormGroup switch>
            <Input
              type="switch"
              fill="red"
              id="checkAdminInput"
              color="secondary"
              style={{ width: "35px", height: "18px", margin: "auto", cursor: "pointer" }}
              checked={row.user_type == "ADMIN"}
              onClick={() => {
                updateUser(
                  row.id,
                  {
                    user_type: row?.user_type === "USER" ? "ADMIN" : "USER",
                  },
                  "checkAdminInput"
                )
              }}
            />
          </FormGroup>
        )
      },
    },
    {
      Header: "Enable Referral",
      filterable: true,
      accessor: row => {
        return (
          <FormGroup switch>
            <Input
              type="switch"
              fill="red"
              id="checkReferral"
              color="secondary"
              style={{ width: "35px", height: "18px", margin: "auto", cursor: "pointer" }}
              checked={row.referral_enabled}
              onClick={() => {
                updateUser(
                  row.id,
                  {
                    referral_enabled: !row.referral_enabled,
                  },
                  "checkReferral"
                )
              }}
            />
          </FormGroup>
        )
      },
    },


  ]

  return (

    <Container className="page-content position-relative" fluid style={{ minHeight: "400px" }}>

      {loading && <DefaultLoading style={{ position: "absolute", width: "90%", height: "100%", minHeight: "400px" }} />}


      <div className="mb-4 h4 card-title">Users</div>
      <Card>
        <CardBody>
          <TableContainerPagination
            columns={columns}
            data={data}
            isSortBy={false}
            isGlobalFilter={true}
            isAddUserList={false}
            isFilter={false}
            customPageSize={10}
            pagination={pagination}
            refetchData={getUserData}
            className="custom-header-css"
            rowClass="cursor-auto-imp"
          />
        </CardBody>
      </Card>


    </Container>

  )
}

export default withRouter(UsersListPage)
