import { STATUS, LEASE_TYPES } from "pages/Utility/constants"
import React, { memo, useState } from "react"
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa"
import CancellationRefundModal from "./CancellationModal";

const ClientBillingDetail = ({
  order_status,
  plane_owner_detail,
  hangar_owner_detail,
  refundedData,
  loading,
  cancellation_reason,
  handleCancellation,
  totalAmount = 0,
  payment_intent = null,
  serviceFee = 0,
  rental_type = "",
  check_in = "",
  check_out = "",
  cancel_at_end = false,
  paymentIntentDetails,
  setPaymentIntentDetails,
  subscription_status,
  last_payment_amount,
  invoiceDetail
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isRefundMode, setIsRefundMode] = useState(false);
  const [isMonthlyRefund, setIsMonthlyRefund] = useState(false);

  const rental_type_monthly = rental_type === LEASE_TYPES.MONTHLY;
  const isPendingStatus = order_status === STATUS.ORDER_INPROCESS;
  const isSubscriptionCanceled = subscription_status === "cancel";
  const isOrderApproved = order_status === STATUS.ORDER_APPROVED;
  const isCanceledStatus = order_status === STATUS.ORDER_CANCELED && rental_type === LEASE_TYPES.DAILY;
  const refundedPaymentStatuses = payment_intent?.payment_status === "refunded" || payment_intent?.payment_status === "partial_refunded";

  // Determine if we can do End & Refund for monthly subscriptions
  const statuses = ["incomplete", "incomplete_expired", "trialing"]
  const canEndAndRefundMonthly = rental_type_monthly && isOrderApproved && !isSubscriptionCanceled && !statuses?.includes(invoiceDetail?.stripe_subscription_status);
  // Check if check-in has started (for daily rentals)
  const hasCheckInStarted = check_in && new Date(check_in) <= new Date();

  // Disable End & Refund button in these scenarios
  const isEndRefundButtonDisabled = !canEndAndRefundMonthly ||
    loading ||
    (rental_type_monthly && (isPendingStatus || isSubscriptionCanceled));

  const Heading = ({ text, customClass }) => (
    <nav aria-label="breadcrumb">
      <ol className={`breadcrumb ${customClass}`}>
        <li
          style={{ fontWeight: "600", color: "#495057" }}
          className="breadcrumb-item active"
          aria-current="page"
        >
          {text}
        </li>
      </ol>
    </nav>
  )

  // For daily rentals or monthly in pending state, disable buttons for these states
  const DAILY_DISABLED_STATES = [
    STATUS.ORDER_REFUNDED,
    STATUS.ORDER_CANCELED,
    STATUS.ORDER_REJECTED,
    STATUS.ORDER_AUTO_CANCEL,
  ]

  // Only add ORDER_APPROVED to disabled states if check-in has started
  if (isOrderApproved && hasCheckInStarted && !rental_type_monthly) {
    DAILY_DISABLED_STATES.push(STATUS.ORDER_APPROVED);
  }

  // For monthly rentals, only disable in these specific states
  const MONTHLY_DISABLED_STATES = [
    STATUS.ORDER_REFUNDED,
    STATUS.ORDER_CANCELED,
    STATUS.ORDER_REJECTED,
  ]

  const DISABLED_STATES = rental_type_monthly && !isPendingStatus
    ? MONTHLY_DISABLED_STATES
    : DAILY_DISABLED_STATES

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const openCancellationModal = () => {
    setIsRefundMode(false);
    setIsMonthlyRefund(false);
    setModalOpen(true);
  };

  const openRefundModal = () => {
    setIsRefundMode(true);
    setIsMonthlyRefund(false);
    setModalOpen(true);
  };

  const openRefundOnlyModal = () => {
    setIsRefundMode(true);
    setIsMonthlyRefund(false);
    setModalOpen(true);
  };

  const openMonthlyEndRefundModal = () => {
    setIsRefundMode(false);
    setIsMonthlyRefund(true);
    setModalOpen(true);
  };

  const handleModalSubmit = (data) => {
    handleCancellation(data);
  };

  const renderProfileRow = (imgSrc, content) => (
    <div className="d-flex align-items-center gap-2 px-3 py-2">
      <div style={{ minWidth: "26px", display: "flex", alignItems: "center" }}>
        <img
          style={{ height: "26px", width: "26px", borderRadius: "50%", objectFit: "cover" }}
          src={imgSrc || "/images/profile.png"}
          alt="Profile"
          onError={(e) => {
            e.target.src = "/images/profile.png";
            e.target.onerror = null;
          }}
        />
      </div>
      <div className="text-truncate" style={{ maxWidth: "calc(100% - 30px)" }}>
        {content}
      </div>
    </div>
  )

  const renderIconRow = (Icon, content, color = "#6c757d") => (
    <div className="d-flex align-items-center gap-2 px-3 py-2">
      <div style={{ minWidth: "26px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Icon size={16} color={color} />
      </div>
      <div className="text-truncate" style={{ maxWidth: "calc(100% - 30px)" }}>
        {content}
      </div>
    </div>
  )

  // Extract payment intent ID
  const paymentIntentId = payment_intent?.stripe_payment_intent || null;

  const getCancelButtonText = () => {
    if (rental_type_monthly && !isPendingStatus) {
      return "End Reservation";
    }
    return "Cancel Reservation";
  };

  return (
    <div>
      <CancellationRefundModal
        isOpen={modalOpen}
        toggle={handleModalToggle}
        handleCancellation={handleModalSubmit}
        totalAmount={totalAmount}
        isRefundMode={isRefundMode}
        isMonthlyRefund={isMonthlyRefund}
        setIsMonthlyRefund={setIsMonthlyRefund}
        paymentIntentId={paymentIntentId}
        serviceFee={serviceFee}
        rental_type={rental_type}
        order_status={order_status}
        check_out={check_out}
        isPendingStatus={isPendingStatus}
        subscription_status={subscription_status}
        cancel_at_end={cancel_at_end}
        last_payment_amount={last_payment_amount}
        isSubscriptionCanceled={isSubscriptionCanceled}
        canEndAndRefundMonthly={canEndAndRefundMonthly}
        isOrderApproved={isOrderApproved}
        paymentIntentDetails={paymentIntentDetails}
        setPaymentIntentDetails={setPaymentIntentDetails}
        isCanceledStatus={isCanceledStatus}
      />

      <div className="container px-2">
        <div className="row mx-0 pb-3">
          <div className="d-flex flex-column gap-2">
            {/* Refund Only button - only show for canceled orders */}
            {isCanceledStatus && (
              <button
                disabled={loading || refundedPaymentStatuses}
                onClick={openRefundOnlyModal}
                className="btn btn-danger w-100"
              >
                Refund
              </button>
            )}

            {/* Cancel/End Reservation button - hide when canceled */}
            {!isCanceledStatus && (
              <button
                disabled={DISABLED_STATES.includes(+order_status) || loading || invoiceDetail?.stripe_subscription_status == "canceled"}
                onClick={openCancellationModal}
                className="btn btn-danger w-100"
              >
                {getCancelButtonText()}
              </button>
            )}

            {/* Only show Refund button for daily rentals or monthly in pending state - hide when canceled */}
            {!rental_type_monthly && !isCanceledStatus && (
              <button
                disabled={DISABLED_STATES.includes(+order_status) || loading}
                onClick={openRefundModal}
                className="btn btn-primary w-100"
              >
                Cancel and Refund Reservation
              </button>
            )}

            {/* End & Refund button for monthly rentals - hide when canceled */}
            {rental_type_monthly && canEndAndRefundMonthly && !isCanceledStatus && (
              <button
                disabled={isEndRefundButtonDisabled || invoiceDetail?.stripe_subscription_status == "canceled" || loading}
                onClick={openMonthlyEndRefundModal}
                className="btn btn-primary w-100"
              >
                End & Refund Reservation
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="mx-2 pb-2 bg-white rounded">
        <Heading text="Hangar Owner Details" customClass="m-0" />

        {/* Profile image with name */}
        {renderProfileRow(
          hangar_owner_detail?.meta?.img_url,
          hangar_owner_detail?.full_name || "N/A"
        )}

        {/* Email with icon */}
        {renderIconRow(
          FaEnvelope,
          hangar_owner_detail?.email || "N/A"
        )}

        {/* Phone with icon */}
        {hangar_owner_detail?.phone && renderIconRow(
          FaPhone,
          hangar_owner_detail.phone
        )}

        {/* Location with icon */}
        {hangar_owner_detail?.meta?.location && renderIconRow(
          FaMapMarkerAlt,
          hangar_owner_detail.meta.location
        )}
      </div>

      <div className="mx-2 mt-4 pb-2 bg-white rounded">
        <Heading text="Plane Owner Details" customClass="m-0" />

        {/* Profile image with name */}
        {renderProfileRow(
          plane_owner_detail?.meta?.img_url,
          plane_owner_detail?.full_name || "N/A"
        )}

        {/* Email with icon */}
        {renderIconRow(
          FaEnvelope,
          plane_owner_detail?.email || "N/A"
        )}

        {/* Phone with icon */}
        {plane_owner_detail?.phone && renderIconRow(
          FaPhone,
          plane_owner_detail.phone
        )}

        {/* Location with icon */}
        {plane_owner_detail?.meta?.location && renderIconRow(
          FaMapMarkerAlt,
          plane_owner_detail.meta.location
        )}
      </div>

      {cancellation_reason && (
        <div className="mx-2 pb-2 mt-3 bg-white border border-danger rounded">
          <Heading text={`${order_status === STATUS.ORDER_REJECTED ? "Hangar Owner" : "Plane Owner"} Cancellation Reason`} customClass="m-0" />
          <div className="px-3 py-2 " style={{ color: "#FF0000" }}>
            <div>{cancellation_reason}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(ClientBillingDetail)