import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { supabase } from "pages/Utility/supabase";
import moment from "moment";
import { toast } from "react-toastify";

// Components
import StackedColumnChart from "./StackedColumnChart";
import MonthlyEarning from "./MonthlyEarning";
import DefaultLoading from "common/loading";
import WelcomeComp from "./WelcomeComp";
import UpcomingCheckIns from "components/Dashboard/UpcomingCheckIn";
import UpcomingCheckOuts from "components/Dashboard/UpcomingCheckOut";

// Redux
import {
  getChartsData as onGetChartsData,
  logoutUser,
} from "../../store/actions";

// Constants
import { DATE_FORMATS, LEASE_TYPES, SERVICE_FEE, STATUS, TABLES } from "pages/Utility/constants";

const CHART_TYPES = {
  DAILY: 0,
  MONTHLY: 1,
  YEARLY: 2,
};

const DAILY_LABELS = Array(30)
  .fill()
  .map((_, i) => moment().subtract(i, "day").format("DD"))
  .reverse();

const MONTHLY_LABELS = Array(12)
  .fill()
  .map((_, i) => moment().subtract(i, "month").format("MMM"))
  .reverse();

const YEARLY_LABELS = Array(11)
  .fill()
  .map((i) => moment().subtract(i, "year").format("YYYY"))
  .reverse();

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Main dashboard data
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState(CHART_TYPES.MONTHLY);
  const [monthlyIncome, setMonthlyIncome] = useState([]);
  const [transactionChartData, setTransactionChartData] = useState([]);
  const [labels, setLabels] = useState(MONTHLY_LABELS);
  const [refundAmount, setRefundAmount] = useState(0);
  const [stats, setStats] = useState({
    // Reservation stats
    reservationTotals: 0, reservationsActive: 0, reservationsRejected: 0, reservationsCompleted: 0,
    // Payment stats
    totalRevenue: 0, tax: 0, hostPayouts: 0, earnings: 0
  });

  // Check-In data
  const [checkInsLoading, setCheckInsLoading] = useState(false);
  const [checkInsData, setCheckInsData] = useState([]);
  const [checkInPagination, setCheckInPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    canNextPage: false,
    canPreviousPage: false
  });

  // Check-Out data
  const [checkOutsLoading, setCheckOutsLoading] = useState(false);
  const [checkOutsData, setCheckOutsData] = useState([]);
  const [checkOutPagination, setCheckOutPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    canNextPage: false,
    canPreviousPage: false
  });

  // Query fields - select all needed fields
  const QUERY_FIELDS = `*, hangar_owner(*), user_id(*)`;

  const { chartsData } = props.DashboardStore;
  const sum = transactionData?.reduce(
    (acc, item) => acc + parseFloat(item.total),
    0
  );

  const reports = [
    {
      title: "Reservations Total",
      iconClass: "bx-copy-alt",
      description: stats.reservationTotals,
    },
    {
      title: "Reservations Active",
      iconClass: "bx-copy-alt",
      description: stats.reservationsActive,
    },
    {
      title: "Reservations Completed",
      iconClass: "bx-copy-alt",
      description: stats.reservationsCompleted,
    },
    {
      title: "Reservations Canceled/Rejected",
      iconClass: "bx-copy-alt",
      description: stats.reservationsRejected,
    },
    {
      title: "Total Revenue Collected",
      iconClass: "bx-archive-in",
      description: "$" + (stats.totalRevenue || 0).toFixed(2),
    },
    {
      title: "Taxes Collected",
      iconClass: "bx-purchase-tag-alt",
      description: "$" + (stats.tax || 0).toFixed(2),
    },
    {
      title: "Refunded",
      iconClass: "bx-purchase-tag-alt",
      description: "$" + (stats.refundAmount || 0).toFixed(2),
    },
    {
      title: "Host Payouts",
      iconClass: "bx-purchase-tag-alt",
      description: "$" + (stats.hostPayouts || 0).toFixed(2),
    },
    {
      title: "Platform Earnings",
      iconClass: "bx-purchase-tag-alt",
      description: "$" + (stats.earnings || 0).toFixed(2),
    },
  ];

  // Navigate to reservation details
  const navigateToReservationDetails = (id) => {
    history.push(`/invoices-detail/${id}`);
  };

  const fetchUpcomingCheckIns = async (parameter = {}) => {
    try {
      setCheckInsLoading(true);

      const startDate = moment().add(1, 'day').startOf('day').format(DATE_FORMATS.LONG);
      const endDate = moment().add(3, 'days').endOf('day').format(DATE_FORMATS.LONG);

      const countQuery = await supabase
        .from(TABLES.RESERVATIONS)
        .select('id', { count: 'exact' })
        .gte('check_in', startDate)
        .lte('check_in', endDate)
        .eq('order_status', 6);

      if (countQuery.error) {
        console.error("Error counting check-ins:", countQuery.error.message);
        toast.error(countQuery.error.message);
        return;
      }

      const totalCount = countQuery.count || 0;

      const pageSize = parameter?.pageSize || 10;
      const page = parameter?.page || 1;
      const from = (page - 1) * pageSize;
      const to = from + pageSize - 1;

      const result = await supabase
        .from(TABLES.RESERVATIONS)
        .select(QUERY_FIELDS)
        .gte('check_in', startDate)
        .lte('check_in', endDate)
        .eq('order_status', 6)
        .order('check_in', { ascending: true })
        .range(from, to);

      if (result.error) {
        console.error("Error fetching check-ins:", result.error.message);
        toast.error(result.error.message);
        setCheckInsData([]);
      } else {
        setCheckInsData(result.data || []);
      }

      const totalPages = Math.ceil(totalCount / pageSize);
      setCheckInPagination({
        currentPage: page,
        pageSize: pageSize,
        totalCount: totalCount,
        totalPages: totalPages,
        canNextPage: page < totalPages,
        canPreviousPage: page > 1
      });

    } catch (error) {
      console.error("Error fetching check-ins:", error.message);
      toast.error(error.message);
      setCheckInsData([]);
    } finally {
      setCheckInsLoading(false);
    }
  };

  const fetchUpcomingCheckOuts = async (parameter = {}) => {
    try {
      setCheckOutsLoading(true);

      //date range: start from tomorrow and include the next 3 days
      const startDate = moment().add(1, 'day').startOf('day').format(DATE_FORMATS.LONG);
      const endDate = moment().add(3, 'days').endOf('day').format(DATE_FORMATS.LONG);

      let [dailyResult, monthlyResult] = await Promise.all([
        supabase
          .from(TABLES.RESERVATIONS)
          .select('id', { count: 'exact' })
          .eq('rental_type', LEASE_TYPES.DAILY)
          .gte('check_out', startDate)
          .lte('check_out', endDate)
          .eq('order_status', 8),

        supabase
          .from(TABLES.RESERVATIONS)
          .select('id', { count: 'exact' })
          .eq('rental_type', LEASE_TYPES.MONTHLY)
          .gte('check_out', startDate)
          .lte('check_out', endDate)
          .eq('order_status', 8)
          .or('stripe_subscription_status.eq.canceled,cancel_at_end.eq.true')
      ]);

      if (dailyResult.error) {
        console.error("Error counting daily check-outs:", dailyResult.error.message);
        toast.error(dailyResult.error.message);
        return;
      }

      if (monthlyResult.error) {
        console.error("Error counting monthly check-outs:", monthlyResult.error.message);
        toast.error(monthlyResult.error.message);
        return;
      }

      const totalCount = (dailyResult.count || 0) + (monthlyResult.count || 0);

      const pageSize = parameter?.pageSize || 10;
      const page = parameter?.page || 1;
      const totalPages = Math.ceil(totalCount / pageSize);

      const [dailyData, monthlyData] = await Promise.all([
        supabase
          .from(TABLES.RESERVATIONS)
          .select(QUERY_FIELDS)
          .eq('rental_type', LEASE_TYPES.DAILY)
          .gte('check_out', startDate)
          .lte('check_out', endDate)
          .eq('order_status', 8)
          .order('check_out', { ascending: true }),

        supabase
          .from(TABLES.RESERVATIONS)
          .select(QUERY_FIELDS)
          .eq('rental_type', LEASE_TYPES.MONTHLY)
          .gte('check_out', startDate)
          .lte('check_out', endDate)
          .eq('order_status', 8)
          .or('stripe_subscription_status.eq.canceled,cancel_at_end.eq.true')
          .order('check_out', { ascending: true })
      ]);

      if (dailyData.error) {
        console.error("Error fetching daily check-outs:", dailyData.error.message);
        toast.error(dailyData.error.message);
        return;
      }

      if (monthlyData.error) {
        console.error("Error fetching monthly check-outs:", monthlyData.error.message);
        toast.error(monthlyData.error.message);
        return;
      }

      // Combine and sort data
      const allData = [...(dailyData.data || []), ...(monthlyData.data || [])];
      const sortedData = allData.sort((a, b) =>
        moment(a.check_out).diff(moment(b.check_out))
      );

      // Apply pagination manually since we combined two queries
      const startIndex = (page - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, sortedData.length);
      const paginatedData = sortedData.slice(startIndex, endIndex);

      setCheckOutsData(paginatedData);
      setCheckOutPagination({
        currentPage: page,
        pageSize: pageSize,
        totalCount: totalCount,
        totalPages: totalPages,
        canNextPage: page < totalPages,
        canPreviousPage: page > 1
      });

    } catch (error) {
      console.error("Error fetching check-outs:", error.message);
      toast.error(error.message);
      setCheckOutsData([]);
    } finally {
      setCheckOutsLoading(false);
    }
  };

  const getHangarsTransactionData = async () => {
    setLoading(true);
    try {
      const [respTransaction, respRefund, respReservations, respReservationActive, respReservationCompleted, respReservationRejected, respNightlyReservation, respMonthlyReservation, respPayouts] = await Promise.all([
        supabase.from(TABLES.TRANSACTIONS_NEW).select("*,total,tax,description,amount").order("id", { ascending: true }).eq("payment_status", "succeeded"),
        supabase.from(TABLES.REFUND).select("refund_amount"),
        supabase.from(TABLES.RESERVATIONS).select("id"),
        supabase.from(TABLES.RESERVATIONS).select("id", { count: "exact" }).eq("order_status", STATUS.ORDER_APPROVED).gt("check_out", new Date().toISOString()),// Checkout date not passed
        supabase.from(TABLES.RESERVATIONS).select("id", { count: "exact" }).eq("order_status", STATUS.ORDER_APPROVED).lte("check_out", new Date().toISOString()), // Checkout date has passed
        supabase.from(TABLES.RESERVATIONS).select("id", { count: "exact" }).or(`order_status.eq.${STATUS.ORDER_AUTO_CANCEL},order_status.eq.${STATUS.ORDER_CANCELED},order_status.eq.${STATUS.ORDER_REJECTED}`), // Rejected or Canceled Reservations
        supabase.from(TABLES.RESERVATIONS).select("id,premium_amenities_amount,reservation_amount,service_fee").eq("order_status", STATUS.ORDER_APPROVED).eq("rental_type", LEASE_TYPES.DAILY), // Nightly Approved Reservations
        supabase.from(TABLES.SUBSCRIPTION_MONTHLY_HISTORY).select("id,reservation_amount,stripe_charge_id(payment_status),amount"), // Monthly Reservation for income
        supabase.from(TABLES.PAYOUTS).select("id,amount").eq("status", STATUS.PAYOUT_COMPLETED), // Monthly Reservation for income
      ]);

      let { data = [], error } = respTransaction;
      let { data: refundData, error: refundDataError } = respRefund;
      let { data: reservations = [] } = respReservations;
      let { data: nightlyReservations = [] } = respNightlyReservation;
      let { data: monthlyReservations = [] } = respMonthlyReservation;
      let { data: payouts = [] } = respPayouts;

      if (error) {
        throw error;
      }

      const refund_amount = refundData.reduce((acc, item) => acc + +item.refund_amount, 0);
      const revenueStats = data?.reduce((acc, item) => {
        acc.total = acc.total + item.total;
        acc.tax = acc.tax + item.tax;
        return acc;
      }, { total: 0, tax: 0 })

      const calculatedIncome = data?.reduce((acc, item) => {
        const date = moment(item?.created_at).format("MMM, YYYY");
        const index = acc?.findIndex(i => i.date === date);
        if (index === -1) {
          acc.push({
            created_at: item.created_at,
            date,
            Amount: +item?.total,
          });
        } else {
          acc[index].Amount += +item?.total;
        }
        return acc;
      }, []);


      // Income From NightlyReservations
      const income = nightlyReservations?.reduce((acc, item) => {
        // income from customer purchase
        acc.dailyReservationIncome = acc.dailyReservationIncome + (item.service_fee || 0);

        // income from host withdraw
        const reservationAmount = (item.premium_amenities_amount || 0) + item.reservation_amount;
        const withdraw_service_fee = (reservationAmount / 100) * SERVICE_FEE.HOST_FEE_WITHDRAW;
        acc.dailyReservationIncome = acc.dailyReservationIncome + withdraw_service_fee;

        return acc;
      }, { dailyReservationIncome: 0 });

      // Income From MonthlyReservation
      const incomeMonthly = monthlyReservations?.reduce((acc, item) => {
        if (item?.stripe_charge_id?.payment_status == "succeeded") {
          // income from customer purchase
          const serviceFee = item?.amount - item.reservation_amount;
          acc.monthlyIncomeReservation = acc.monthlyIncomeReservation + serviceFee;

          // income from host withdraw
          const withdraw_service_fee = (item.reservation_amount / 100) * SERVICE_FEE.HOST_FEE_WITHDRAW
          acc.monthlyIncomeReservation = acc.monthlyIncomeReservation + withdraw_service_fee;
        }

        return acc;
      }, { monthlyIncomeReservation: 0 });

      // Income from promotion packages
      const promotionPackagesIncome = data?.reduce((acc, item) => {
        if (item.description?.includes("promotion")) {
          acc.income = acc.income + item.amount;
        }
        return acc;
      }, { income: 0 })

      // Payouts to host
      const hostPayouts = payouts?.reduce((acc, item) => {
        acc.amount = acc.amount + +item.amount;
        return acc;
      }, { amount: 0 })



      setTransactionData(data);
      setRefundAmount(refund_amount);
      setMonthlyIncome(calculatedIncome);
      setStats({
        ...stats,
        // Reservation stats
        reservationTotals: reservations?.length, reservationsActive: respReservationActive.count, reservationsCompleted: respReservationCompleted.count, reservationsRejected: respReservationRejected.count,
        // Payment Stats
        refundAmount: refund_amount,
        totalRevenue: revenueStats.total,
        tax: revenueStats.tax,
        earnings: income.dailyReservationIncome + incomeMonthly.monthlyIncomeReservation + promotionPackagesIncome?.income,
        hostPayouts: hostPayouts?.amount
      });
    } catch (error) {
      console.error("Error fetching transaction data:", error.message);
    }
    setLoading(false);
  };

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  function handleChangePeriodType(type = periodType) {
    let chartData = [];

    if (type === CHART_TYPES.DAILY)
      chartData = Array(30)
        .fill()
        .map((_, i) => {
          const FORMAT = "YYYY-MM-DD";
          const date = moment().subtract(i, "day").format(FORMAT);
          const found = transactionData.filter(
            i => moment(i.created_at).format(FORMAT) === date
          );
          return found?.length
            ? found
              .reduce((acc, item) => acc + +item.total_Amount, 0)
              .toFixed(2)
            : 0;
        });
    else if (type === CHART_TYPES.MONTHLY) {
      const FORMAT = "MMM, YYYY";
      chartData = Array(12)
        .fill()
        .map((_, i) => {
          const date = moment().subtract(i, "month").format(FORMAT);
          const found = monthlyIncome.find(
            i => moment(i.created_at).format(FORMAT) === date
          );
          return found?.Amount || 0;
        });
    } else if (type === CHART_TYPES.YEARLY) {
      const FORMAT = "YYYY";
      chartData = Array(11)
        .fill()
        .map((_, i) => {
          const date = moment().subtract(i, "year").format(FORMAT);
          const found = monthlyIncome.find(
            i => moment(i.created_at).format(FORMAT) === date
          );
          return found?.Amount || 0;
        });
    }

    const labels =
      type === CHART_TYPES.DAILY
        ? DAILY_LABELS
        : type === CHART_TYPES.MONTHLY
          ? MONTHLY_LABELS
          : YEARLY_LABELS;

    setLabels(labels);
    setTransactionChartData(chartData.reverse());
  }

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (!session) {
      localStorage.removeItem("authUser");
      dispatch(logoutUser(props.history));
    }
  }, []);

  useEffect(() => {
    getHangarsTransactionData();
    fetchUpcomingCheckIns();
    fetchUpcomingCheckOuts();
  }, []);

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  useEffect(() => {
    handleChangePeriodType();
  }, [periodType, monthlyIncome]);

  document.title = "Dashboard | HangarDirect";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <>
              <Row>
                <Col xl="4">
                  <WelcomeComp sum={sum} />
                  <MonthlyEarning income={monthlyIncome} />
                </Col>
                <Col xl="8">
                  <Row>
                    {reports.map((report, key) => (
                      <Col md="4" key={"_col_" + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="fw-medium">{report.title}</p>
                                <h4 className="mb-0">{report.description}</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx " + report.iconClass + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Transaction History</h4>
                        <div className="ms-auto">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <a
                                href="#"
                                className={
                                  periodType === CHART_TYPES.DAILY
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() => {
                                  onChangeChartPeriod(CHART_TYPES.DAILY);
                                }}
                                id="one_day"
                              >
                                Daily
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#"
                                className={
                                  periodType === CHART_TYPES.MONTHLY
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() => {
                                  onChangeChartPeriod(CHART_TYPES.MONTHLY);
                                }}
                                id="one_month"
                              >
                                Month
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#"
                                className={
                                  periodType === CHART_TYPES.YEARLY
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() => {
                                  onChangeChartPeriod(CHART_TYPES.YEARLY);
                                }}
                                id="one_year"
                              >
                                Year
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <StackedColumnChart
                        periodData={periodData}
                        data={transactionChartData}
                        labels={labels}
                        dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* Check-Ins and Check-Outs Section */}
              <Row>
                <Col>
                  <UpcomingCheckIns
                    data={checkInsData}
                    loading={checkInsLoading}
                    pagination={checkInPagination}
                    fetchData={fetchUpcomingCheckIns}
                    navigateToDetails={navigateToReservationDetails}
                    className="h-100"
                  />
                </Col>
                <Col>
                  <UpcomingCheckOuts
                    data={checkOutsData}
                    loading={checkOutsLoading}
                    pagination={checkOutPagination}
                    fetchData={fetchUpcomingCheckOuts}
                    navigateToDetails={navigateToReservationDetails}
                    className="h-100"
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect(s => ({ DashboardStore: s.Dashboard }))(Dashboard);