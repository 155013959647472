import React, { useCallback } from "react"
import { Container,   Card, CardBody } from "reactstrap"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import { getImageUrl } from "pages/Utility/functions"
import { deleteS3File } from "helpers/file-uploader-s3"
import Swal from "sweetalert2"
import TableContainer from "components/Common/TableContainer"
import HorizontalMenu from "common/HorizontalMenu"
import moment from "moment"
import { Link } from "react-router-dom"

const STATUS = {
  GENERAL: "general",
  HANGAR_OWNER: "hangar_owner",
  CUSTOMER: "customer",
}

const HelpDeskArticles = () => {

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [allData, setAllData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const MENUS = [
    { label: "General", value: STATUS.GENERAL },
    { label: "Hangar owner", value: STATUS.HANGAR_OWNER },
    { label: "Customer", value: STATUS.CUSTOMER },
  ]

  const [tabFilter, setTabFilter] = useState(STATUS.GENERAL)

  const fetchData = async () => {
    setLoading(true);
    try {
      let { data, error } = await supabase
        .from(TABLES.HELP_DESK)
        .select("id, title, image, hangar_owner, customer, created_at, slug")
        .order("id", { ascending: true });

      if (error) throw error;

      const filteredData = data.filter(item => {
        if (tabFilter === STATUS.GENERAL) return item?.hangar_owner && item?.customer;
        if (tabFilter === STATUS.HANGAR_OWNER) return item?.hangar_owner && !item?.customer;
        if (tabFilter === STATUS.CUSTOMER) return !item?.hangar_owner && item?.customer;
        return true;
      });

      setAllData(data);
      setCurrentItems(filteredData);

    } catch (error) {
      toast.error(error.message)
      console.error(error.message);
    }
    setLoading(false);
  };

  const handleFilterClick = useCallback(type => {
    const resp = allData.filter(item =>
      type === STATUS.HANGAR_OWNER
        ? item?.hangar_owner && !item?.customer
        : type === STATUS.CUSTOMER
          ? !item?.hangar_owner && item?.customer
          : item?.customer && item?.hangar_owner
    )
    setTabFilter(type)
    setCurrentItems(resp)
  }, [allData])

  const deleteConfirmation = (row) => {
    Swal.fire({
      title: "Do you want to delete this article?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`
    }).then((result) => {
      if (result.isDenied) {
        handleDelete(row)
      }
      Swal.close()
    });
  }

  const handleDelete = async (row) => {
    try {
      const { error } = await supabase
        .from(TABLES.HELP_DESK)
        .delete()
        .eq('id', row.id);

      if (error) {
        throw error;
      }
      toast.success("Article deleted successfully")
      if (row.image) deleteS3File(row.image)
      fetchData();
    } catch (error) {
      console.error('Error deleting record:', error.message);
    }
  };

  useEffect(() => {
    fetchData(STATUS.GENERAL)
  }, [])

  document.title = "Help desk | HangarDirect"

  const columns = useMemo(
    () => [

      {
        Header: "Id",
        filterable: false,
        disableFilters: true,
        accessor: row => <Link to="#" className="text-body fw-bold">
          {row.id}
        </Link>
        ,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Image",
        accessor: row => row.image && <img src={getImageUrl(row.image)} style={{ width: "40px", height: "40px" }} />,
        disableFilters: true,
        filterable: false,

      },

      {
        Header: "Type",
        accessor: ({ hangar_owner, customer }) => hangar_owner && customer
          ? "General"
          : hangar_owner
            ? "Hangar owner"
            : "Customer",
        disableFilters: true,
        filterable: false,

      },
      {
        Header: "Created date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return moment(cellProps.row.original.created_at).format("LL")
        },
      },

      {
        Header: "Action",
        disableFilters: true,
        accessor: row => {
          return (
            <div className="d-flex gap-2 align-items-center" style={{ width: "100px" }}>
              <a target="_blank" rel="noreferrer" href={`${window?.location?.origin?.includes("test-admin.hangardirect.com") || window?.location?.origin?.includes("localhost:3000") ?
                "https://hangardirect-github-io.vercel.app"
                :
                "https://hangardirect.com"
              }/help-desk/${row.slug}`}>
                <svg
                  fill="#000000"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"

                >
                  <path d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
                </svg>
              </a>
              <img onClick={() => {
                history.push("/create-article?id=" + row.id)
              }} className="cursor-pointer" style={{ height: "25px" }} src="/images/editart.png" alt="" />
              <img onClick={() => deleteConfirmation(row)} className="cursor-pointer" style={{ height: "25px" }} src="/images/delart.png" alt="" />
            </div>
          )
        },

      },
    ],
    []
  )

  return (

    <Container fluid className="page-content">
      {loading ? (
        <DefaultLoading />
      ) : (
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="card-title">Help Desk</div>

              <Link to="/create-article">
                <button className="btn btn-info btn-sm px-3 rounded py-2 text-lg mb-2" style={{ fontSize: "16px" }}>
                  + Add Article
                </button>
              </Link>

            </div>

            <TableContainer
              columns={columns}
              data={allData}
              header={<HorizontalMenu
                menu={MENUS}
                getHangarsData={fetchData}
                active={tabFilter}
                onClick={handleFilterClick}
              />}
              isGlobalFilter={false}
              isAddOptions={false}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          </CardBody>
        </Card>
      )}
    </Container>
  )
}

export default HelpDeskArticles

