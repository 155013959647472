import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { BUCKETS, STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import { deleteS3File } from "helpers/file-uploader-s3"
import Swal from "sweetalert2"
import { toast } from "react-toastify"


const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Title = cell => {
  return cell.row.original.title ? cell.row.original.title : ""
}
const Date = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const City = cell => {
  return cell.cell.row.original.city ? cell.cell.row.original.city : ""
}

const Status = cell => {
  return (
    <Badge
      style={{ textTransform: "capitalize", fontSize: "14px", padding: "5px 10px" }}
      className={
        "badge-soft-" +
        (cell.row.original.status == 1
          ? "warning"
          : cell.row.original.status == 2
            ? "success"
            : cell.row.original.status == 3
              ? "danger"
              : "info")
      }
    >
      {cell.row.original.status == 1
        ? "In Review"
        : cell.row.original.status == 2
          ? "Approved"
          : cell.row.original.status == 3
            ? "Rejected"
            : "Deleted!"}
    </Badge>
  )
}
const NearAirport = cell => {
  return (
    <span style={{ textTransform: "capitalize" }}>
      {cell.row?.original?.near_airport}
    </span>
  )
}

const Action = cell => {
  const { id, user_id, images } = cell.row?.original


  const handleDelete = async () => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    let accessToStatusDelete = ["c2a33eee-7f03-46a5-ae3a-c9034dd84049", "f6dbd93a-78cf-4c4a-92e5-2121e08addba", "4808121a-57e5-4a95-91ed-32e724590817"];
    try {
      const confirmationResult = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      });

      if (!confirmationResult.isConfirmed) {
        return;
      }
      else {
        cell?.setLoading(true)
        if (accessToStatusDelete?.includes(user?.id)) {
          const { data, error } = await supabase
            .from(TABLES.HANGARS)
            .update({ "status": STATUS.HANGAR_DELETED })
            .eq("id", id)
            .select();
          if (error) throw error
        } else {

          // checking if transaction exist permission not to delete the hangar
          const { data, error } = await supabase
            .from(TABLES.RESERVATIONS)
            .select("hangar_id")
            .eq("hangar_id", id)
          if (error) throw error
          if (data.length) {
            cell?.setLoading(false)
            return alert("You cannot delete this hangar reservation exist")
          }

          //deleting hangar amenities
          await supabase
            .from(TABLES.HANGAR_AMENITIES)
            .delete()
            .eq("hangar_id", id)

          //deleting hangar rules
          await supabase
            .from(TABLES.HANGAR_RULES)
            .delete()
            .eq("hangar_id", id)

          //deleting property rules
          await supabase
            .from(TABLES.PROPERTY_RULES)
            .delete()
            .eq("hangar_id", id)

          //deleting favorite
          await supabase
            .from(TABLES.FAVORITES)
            .delete()
            .eq("product_id", id)

          // deleting images from storage
          const supaLinks = images.filter(link => link.startsWith("hangar/public"))?.map((img) => img.replace("hangars/", ""))
          const s3Links = images.filter(link => link.includes("s3.amazonaws.com/hangars"))

          if (supaLinks?.length) {
            const { data: dataDelImg, error: ErrDelImg } = await supabase.storage
              .from(BUCKETS.HANGAR_IMAGES)
              .remove(supaLinks)
          }
          if (s3Links)
            await Promise.all(s3Links.map(link => deleteS3File(link)))

          // deleting hangar
          await supabase
            .from(TABLES.HANGARS)
            .delete()
            .eq("id", id)
        }


        cell?.refresh()
        cell?.setLoading(false)
        toast.success("Hangar deleted successfully")
      }


    } catch (error) {
      console.log(error, "error deleting")
      cell?.setLoading(false)
    }
  }

  return (
    <div onClick={handleDelete} className="d-flex marginButton cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-trash" viewBox="0 0 16 16">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
      </svg>
    </div>
  )
}
export { CheckBox, OrderId, Title, Date, City, Status, NearAirport, Action }
