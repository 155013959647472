import { Spinner } from "common/loading";
import FieldError from "components/Common/FieldError";
import uploadFileHandler from "helpers/file-uploader-s3";
import { STATUS, TABLES } from "pages/Utility/constants";
import { isValidHttpUrl } from "pages/Utility/functions";
import { supabase } from "pages/Utility/supabase";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

type Fields = {
    name: string,
    link: string,
    images: {
        mobile: string,
        tablet: string,
        laptop: string,
        xxl: string
    },
    interval: number | string,
    status: boolean
}

const DEFAULT_FIELDS = {
    name: "",
    link: "",
    images: {
        mobile: "",
        tablet: "",
        laptop: "",
        xxl: ""
    },
    status: true
}

const CustomInput = ({ label, value, name, placeholder, onChange, error }) => {
    return (
        <div className="w-full my-2">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{"*"}</span>
            </label>
            <input
                style={{
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8"
                }}
                type="text"
                value={value}
                className={`form-control w-full ${error ? 'is-invalid' : ''}`}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
            />
            <FieldError text={error} />
        </div>
    );
};

const CustomFileInput = ({ label, value, name, onChange, error }) => {
    return (
        <div className="mb-2 col-6">
            <label htmlFor={name} className="form-label my-1">{label}</label>
            <input className="d-none" accept="image/*" type={"file"} id={name} name={name} onChange={onChange} />
            <label htmlFor={name} className="input-group cursor-pointer" >
                <span className="input-group-text" id="basic-addon3">Choose file</span>
                <text type="text" className="form-control text-truncate">{value || "No file chosen"}</text>
            </label>

            <FieldError text={error} />
        </div>
    );
};

const AdvertisementAddModal = ({ visible, toggle, data, interval }) => {

    const [fields, setFields] = useState<Fields>({ ...DEFAULT_FIELDS, })
    const [errors, setErrors] = useState({ ...DEFAULT_FIELDS, mobile: "", tablet: "", laptop: "", xxl: "" })
    const [loading, setLoading] = useState(false)
    const id = data?.id

    function updateState(obj) {
        setFields({ ...fields, ...obj, })
    }

    useEffect(() => {
        if (visible) {
            if (data)
                setFields({ ...fields, ...data, status: data.status == STATUS.ADVERTISEMENT_ACTIVE })
        }
        else {
            setFields(DEFAULT_FIELDS)
        }

    }, [visible])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {


        const { id, name, files, value } = e.target
        if (id === "mobile" || id === "tablet" || id === "laptop" || id === "xxl") {
            const file = files && files[0];
            if (!file) {
                return;
            }
            updateState({ images: { ...fields.images, [name]: file } })
        }
        else if (id === "check") {
            updateState({ [name]: !fields[name] })
        }
        else {
            updateState({ [name]: value })
        }

        setErrors({ ...errors, [name]: "" })
    }

    const didUserChanged = () => {


        let changed = false
        if (fields?.name !== data?.name)
            changed = true
        if (fields?.link !== data?.link)
            changed = true

        if (fields?.images.mobile !== data?.images?.mobile)
            changed = true
        if (fields?.images.tablet !== data?.images?.tablet)
            changed = true
        if (fields?.images.laptop !== data?.images?.laptop)
            changed = true
        if (fields?.images.xxl !== data?.images?.xxl)
            changed = true
        if (fields?.status !== (data?.status === STATUS.ADVERTISEMENT_ACTIVE))
            changed = true


        if (!changed) throw new Error("Nothing to update")

    }
    const validateFields = () => {
        const newErrors: any = {}
        let isValid = true

        if (!fields.name.trim()) {
            newErrors.name = "Name is required"
            isValid = false
        }

        if (!fields.link || !isValidHttpUrl(fields.link)) {
            newErrors.link = "Please provide a valid link"
            isValid = false
        }
        if (!fields.images.mobile) {
            newErrors.mobile = "Mobile image is required"
            isValid = false
        }
        if (!fields.images.tablet) {
            newErrors.tablet = "tablet image is required"
            isValid = false
        }
        if (!fields.images.laptop) {
            newErrors.laptop = "laptop image is required"
            isValid = false
        }
        if (!fields.images.xxl) {
            newErrors.xxl = "XXL image is required"
            isValid = false
        }



        setErrors(newErrors)
        return isValid
    }

    const handleAdd = async () => {

        try {

            if (!validateFields()) {
                return
            }
            if (id) didUserChanged()

            setLoading(true)

            const { name, link, status } = fields
            let images = { ...fields.images }
            const body = {
                id: id || undefined,
                name: name.trim(),
                link: link.trim(),
                status: status ? STATUS.ADVERTISEMENT_ACTIVE : STATUS.ADVERTISEMENT_INACTIVE,
                interval
            }

            if (Object.values(images).some(file => !!file?.name)) {

                const prom = Object.entries(images).map(([key, f]) => !f?.name ? f : uploadFileHandler(f, "advertisements", body.name + "_" + key + "_" + Date.now() + ".png"))

                const urls: string[] = await Promise.all(prom);

                images = {
                    mobile: urls.find(url => url.includes("_mobile_")),
                    tablet: urls.find(url => url.includes("_tablet_")),
                    laptop: urls.find(url => url.includes("_laptop_")),
                    xxl: urls.find(url => url.includes("_xxl_"))
                };

                updateState({ images })


            }

            const { data, error } = await supabase.from(TABLES.ADVERTISEMENT).upsert({ ...body, images })

            if (error) {
                throw error
            } else {
                toast.success(`Advertisement successfully ${id ? 'updated' : 'added'}`)
                toggle(true)
                setFields(DEFAULT_FIELDS)
            }


        } catch (error) {
            toast.clearWaitingQueue()
            toast.error(error.message)
        }
        setLoading(false)
    }

    return (
        <Modal
            isOpen={visible}
            role="dialog"
            autoFocus={true}
            centered={true}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>{id ? "Update" : "Add"} Advertisement</ModalHeader>

            <div className="modal-content">
                <ModalBody>

                    <CustomInput
                        label={"Name"}
                        placeholder={"Enter Name"}
                        name={"name"}
                        onChange={handleChange}
                        value={fields?.name}
                        error={errors.name}
                    />

                    <CustomInput
                        label={"Link"}
                        placeholder={"Enter Link"}
                        name={"link"}
                        onChange={handleChange}
                        value={fields?.link}
                        error={errors.link}
                    />

                    <label
                        htmlFor="titleinput"
                        className="font-weight-bold my-0 form-label fs-5"
                    >
                        Images <span className="text-info">{"*"}</span>
                    </label>
                    <Row >

                        <CustomFileInput value={fields?.images?.mobile?.name || fields?.images?.mobile} name="mobile"
                            onChange={handleChange} label={"Mobile"} error={errors.mobile}
                        />


                        <CustomFileInput value={fields?.images?.tablet?.name || fields?.images?.tablet} name="tablet"
                            onChange={handleChange} label={"Tablet"} error={errors.tablet}
                        />


                    </Row>
                    <Row >

                        <CustomFileInput value={fields?.images?.laptop?.name || fields?.images?.laptop} name="laptop"
                            onChange={handleChange} label={"Laptop"} error={errors.laptop}
                        />

                        <CustomFileInput value={fields?.images?.xxl?.name || fields?.images?.xxl} name="xxl"
                            onChange={handleChange} label={"XXL"} error={errors.xxl}
                        />


                    </Row>


                    <div className="form-check form-switch mb-2">
                        <input className="form-check-input form-label" onClick={handleChange} name="status" checked={fields.status} type="checkbox" role="switch" id="check" />
                        <label className="form-check-label form-label" htmlFor="check">Is Active</label>
                    </div>


                </ModalBody>


                <ModalFooter>
                    <Button type="button" color="primary" onClick={handleAdd}>
                        {loading ? <Spinner hT={"20px"} wT={"20px"} /> : id ? "Update" : "Add"}
                    </Button>
                    <Button type="button" color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default AdvertisementAddModal