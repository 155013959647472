import { Col, Container, Row } from "reactstrap"
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import moment from "moment"
import { DATE_FORMATS, LEASE_TYPES, STATUS, TABLES } from "pages/Utility/constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import React, { useState, useEffect } from "react"
import Alerts from "common/Alerts/Alert"
import { cancelStripeSubscription, refundPayment } from "pages/Utility/stripe_functions"
import Detail from "components/Invoice/Detail"
import ClientBillingDetail from "components/Invoice/ClientBillingDetail"
import RequestModelInvoice from "./requestModel"
import { getImageUrl } from "pages/Utility/functions"
import Swal from "sweetalert2"

const ReservationDetail = props => {
  document.title = "Invoice Details - HangarDirect"

  const {
    match: { params },
  } = props

  const history = useHistory()

  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }

  // State declarations
  const [loading, setLoading] = useState(true)
  const [approval, setApproval] = useState(true)
  const [refundModel, setRefundModel] = useState(false)
  const [invoiceDetail, setInvoiceDetail] = useState(null)
  const [refundedData, setRefundedData] = useState([])
  const [cancelledData, setCancelledData] = useState([])
  const [subscriptionData, setSubscriptionData] = useState([])
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const [cancellationReason, setCancellationReason] = useState('')

  // Derived values
  const dynamic_check = invoiceDetail?.hangar_data?.is_shared_pricing_model && invoiceDetail?.hangar_data?.is_pricing_sq_ft
  const hangar_detail = invoiceDetail?.hangar_id
  const hangar_owner_detail = invoiceDetail?.hangar_owner
  const plane_owner_detail = invoiceDetail?.user_id
  const rental_type = invoiceDetail?.rental_type
  const rental_type_daily = invoiceDetail?.rental_type === LEASE_TYPES.DAILY
  const rental_type_monthly = invoiceDetail?.rental_type === LEASE_TYPES.MONTHLY
  const hangar_per_night = invoiceDetail?.hangar_data?.price_per_night
  const hangar_per_month = invoiceDetail?.hangar_data?.price_per_month
  const payment_intent = invoiceDetail?.stripe_payment_intent
  const check_in = invoiceDetail?.check_in ? moment(invoiceDetail.check_in).format(DATE_FORMATS.LONG) : ''
  const check_in_without_format = invoiceDetail?.check_in || ''
  const check_out = invoiceDetail?.check_out ? moment(invoiceDetail.check_out).format(DATE_FORMATS.LONG) : ''
  const check_out_without_format = invoiceDetail?.check_out || ''
  const created_at = invoiceDetail?.created_at ? moment(invoiceDetail.created_at).format(DATE_FORMATS.LONG) : ''
  const order_status = invoiceDetail?.order_status
  const subscription_status = invoiceDetail?.stripe_subscription_status
  const cancel_at_end = invoiceDetail?.cancel_at_end
  const tax = invoiceDetail?.tax
  const serviceFee = invoiceDetail?.service_fee
  const credit = invoiceDetail?.credit
  const reservation_amount = invoiceDetail?.reservation_amount
  const total = invoiceDetail?.total
  const premium_amenities_amount = invoiceDetail?.premium_amenities_amount
  const tail_number = invoiceDetail?.tail_number
  const aircraft_name = invoiceDetail?.aircraft_name
  const cancellation_reason = invoiceDetail?.cancellation_reason;
  const plane_area_sq_ft = invoiceDetail?.plane_area_sq_ft
  const day = invoiceDetail?.day

  const [paymentIntentDetails, setPaymentIntentDetails] = useState(null);

  useEffect(() => {
    if (params && params.id) {
      getReservationData(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (payment_intent?.id) {
      getRefundedOrderData(payment_intent.id);
    }
  }, [payment_intent?.id]);


  const getReservationData = async (id) => {
    setLoading(true);
    try {
      // Fetch main reservation data
      const { data, error } = await supabase
        .from(TABLES.RESERVATIONS)
        .select("*,user_id(*),hangar_owner(*),hangar_id(*),stripe_payment_intent (*), review (*)")
        .eq("id", id)
        .single();

      if (error) throw error;

      if (data) {
        setInvoiceDetail(data);

        if (data.rental_type === LEASE_TYPES.MONTHLY) {
          const { data: subscriptionHistoryData, error: subscriptionHistoryError } = await supabase
            .from(TABLES.SUBSCRIPTION_MONTHLY_HISTORY)
            .select("*")
            .eq("reservation_id", id)
            .order("created_at", { ascending: false });

          if (!subscriptionHistoryError && subscriptionHistoryData?.length) {
            setSubscriptionData({
              ...subscriptionData,
              history: subscriptionHistoryData
            });
          } else {
            setSubscriptionData(subscriptionData);
          }
        } else {
          setSubscriptionData(subscriptionData);
        }
      }
    } catch (error) {
      console.log("Error fetching reservation data:", error.message);
    } finally {
      setLoading(false);
    }
  }

  const getRefundedOrderData = async (transactionId) => {
    try {
      const { data, error } = await supabase
        .from(TABLES.REFUND)
        .select("*")
        .eq("transaction_id", transactionId)

      if (error) throw error
      // Save as a single object if data exists, instead of array
      if (data?.length) setRefundedData(data[0])
    } catch (error) {
      console.error("Error fetching refund data:", error.message)
    }
  }

  const showConfirmation = async (options) => {
    const { title, text, confirmText = "Yes, proceed!", cancelText = "No, cancel" } = options;

    const result = await Swal.fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText
    });

    return result.isConfirmed;
  };

  const updateReservation = async (data) => {
    try {
      const { error } = await supabase
        .from(TABLES.RESERVATIONS)
        .update(data)
        .eq("id", invoiceDetail.id);

      if (error) throw error;

      setCancellationReason(data.cancellation_reason);
      await getReservationData(invoiceDetail.id);
      return true;
    } catch (error) {
      console.error("Error updating reservation:", error);
      return false;
    }
  };


  const handleCancellation = async (cancellationData) => {
    setLoading(true);

    try {
      const isRefund = !!cancellationData.refund_type;
      const isMonthlyActive = rental_type_monthly && order_status !== STATUS.ORDER_INPROCESS;
      const isMonthlyRefund = isMonthlyActive && cancellationData.is_monthly_refund;
      const isCanceledStatus = order_status === STATUS.ORDER_CANCELED && rental_type === LEASE_TYPES.DAILY;

      // case for refund-only on canceled orders
      if (isCanceledStatus && isRefund) {
        if (!invoiceDetail?.stripe_payment_intent?.id) {
          Swal.fire(
            "Error!",
            "Cannot process refund: Payment intent not found.",
            "error"
          );
          setLoading(false);
          return;
        }

        const confirmed = await showConfirmation({
          title: "Process Refund?",
          text: "This will process a refund for the canceled reservation.",
          confirmText: "Yes, Process Refund",
          cancelText: "No, Cancel"
        });

        if (!confirmed) {
          setLoading(false);
          return;
        }

        // process the refund
        const refundAmount = cancellationData.refund_amount;
        const stripeFee = cancellationData.apply_stripe_fee ? cancellationData.stripe_fee : 0;

        const refundResult = await refundPayment({
          invoiceDetail: {
            ...invoiceDetail,
            total_Amount: refundAmount,
            stripeFee: stripeFee,
          },
          reasonTxt: "Refund for canceled reservation",
          paymentIntentDetails,
          cancellationData
        });

        if (refundResult.success) {
          await getReservationData(invoiceDetail.id);

          const refundType = cancellationData.refund_type === "full" ? "Full" : "Partial";

          Swal.fire(
            `${refundType} Refund Processed!`,
            `A refund of $${parseFloat(refundAmount).toFixed(2)} has been processed.`,
            "success"
          );
        } else {
          Swal.fire(
            "Error!",
            `Failed to process refund: ${refundResult.error}`,
            "error"
          );
        }

        setLoading(false);
        return;
      }

      // monthly subscription end
      if (isMonthlyActive) {
        const endTypeText = cancellationData.end_type === "immediate" && order_status !== STATUS.ORDER_INPROCESS
          ? "immediately"
          : "at the end of the current period";

        const confirmed = await showConfirmation({
          title: isMonthlyRefund ? "End & Refund Subscription?" : "End Subscription?",
          text: isMonthlyRefund
            ? `This will end the subscription ${endTypeText} and refund the last payment of $${parseFloat(cancellationData.refund_amount).toFixed(2)}. Are you sure?`
            : `This will end the subscription ${endTypeText}. Are you sure?`,
          confirmText: isMonthlyRefund ? "Yes, End & Refund" : "Yes, End Subscription",
          cancelText: "No, Cancel"
        });

        if (!confirmed) {
          setLoading(false);
          return;
        }

        const updateData = {
        };

        // Only add cancellation reason if it exists
        if (cancellationData.cancellation_reason) {
          updateData.cancellation_reason = cancellationData.cancellation_reason;
        }



        // Process monthly refund if requested
        if (isMonthlyRefund) {
          // Refund the last payment
          const refundResult = await refundPayment({
            invoiceDetail: {
              ...invoiceDetail,
              total_Amount: cancellationData.refund_amount,
              stripeFee: 0, // No stripe fee for monthly refunds
            },
            reasonTxt: "Monthly subscription canceled with refund",
            paymentIntentDetails,
            cancellationData,
            isMonthlyRefund: true
          });

          if (!refundResult.success) {
            throw new Error(`Failed to process refund: ${refundResult.error}`);
          }


        }


        const respSubscription = await cancelStripeSubscription({ cancelNow: cancellationData.end_type === "immediate", subscription_id: invoiceDetail.stripe_subscription_id });


        if (respSubscription?.success) {
          getReservationData(invoiceDetail.id);
          Swal.fire(
            "Success!",
            isMonthlyRefund
              ? `The subscription will end ${endTypeText} and a refund of $${parseFloat(cancellationData.refund_amount).toFixed(2)} has been processed.`
              : `The subscription will end ${endTypeText}.`,
            "success"
          );
        } else {
          throw new Error("Failed to update subscription");
        }

        // Return the function and fetch updated details
        return
      }

      // regular refund/cancellation logic for non-monthly or pending monthly
      const confirmed = await showConfirmation({
        title: isRefund ? "Process Refund?" : "Are you sure?",
        text: isRefund
          ? "This will cancel the reservation and process a refund."
          : "This will cancel the reservation and cannot be undone."
      });

      if (!confirmed) {
        setLoading(false);
        return;
      }

      if (isRefund && !invoiceDetail?.stripe_payment_intent?.id) {
        Swal.fire(
          "Error!",
          "Cannot process refund: Payment intent not found.",
          "error"
        );
        setLoading(false);
        return;
      }

      if (!isRefund) {
        const updated = await updateReservation({
          order_status: cancellationData.order_status,
          cancellation_reason: cancellationData.cancellation_reason
        });

        if (updated) {
          Swal.fire(
            "Cancelled!",
            "The reservation has been cancelled successfully.",
            "success"
          );
        } else {
          throw new Error("Failed to update reservation");
        }

        return;
      }

      // process the refund
      const refundAmount = cancellationData.refund_amount;
      const stripeFee = cancellationData.apply_stripe_fee ? cancellationData.stripe_fee : 0;

      const refundResult = await refundPayment({
        invoiceDetail: {
          ...invoiceDetail,
          total_Amount: refundAmount,
          stripeFee: stripeFee,
        },
        reasonTxt: cancellationData.cancellation_reason,
        paymentIntentDetails,
        cancellationData
      });

      if (refundResult.success) {
        await updateReservation({
          order_status: cancellationData?.order_status,
          cancellation_reason: cancellationData.cancellation_reason
        });

        await getReservationData(invoiceDetail.id);
        setCancellationReason(cancellationData.cancellation_reason);

        const refundType = cancellationData.refund_type === "full" ? "Full" : "Partial";

        Swal.fire(
          `${refundType} Refund Processed!`,
          `The reservation has been cancelled and a refund of $${parseFloat(refundAmount).toFixed(2)} has been processed.`,
          "success"
        );
      } else {
        Swal.fire(
          "Error!",
          `Failed to process refund: ${refundResult.error}`,
          "error"
        );
      }
    } catch (error) {
      console.error("Error in cancellation process:", error);

      Swal.fire(
        "Error!",
        "There was an error processing your request. Please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };


  // UI Helper functions
  const printInvoice = () => window.print()

  const Heading = ({ text, customClass }) => (
    <nav aria-label="breadcrumb">
      <ol className={`breadcrumb ${customClass}`}>
        <li
          style={{ fontWeight: "600", color: "#495057" }}
          className="breadcrumb-item active"
          aria-current="page"
        >
          {text}
        </li>
      </ol>
    </nav>
  )


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <>

              {!approval && (
                <Alerts
                  type={alert.type}
                  heading={alert.heading}
                  message={alert.message}
                  clear={() => setAlert(DEFAULT_ALERT_VALUES)}
                />
              )}

              <div className="d-flex justify-content-between">
                <Heading
                  text={`Reservation# ${invoiceDetail?.id}`}
                  customClass="px-0"
                />
                <div>
                  <button
                    onClick={printInvoice}
                    style={{ backgroundColor: "#556EE6", color: "white" }}
                    className="btn"
                  >
                    Print preview
                  </button>
                  <button
                    onClick={() => history.goBack()}
                    style={{
                      backgroundColor: "#74788D",
                      color: "white",
                      marginLeft: "8px",
                    }}
                    className="btn"
                  >
                    Go back
                  </button>
                </div>
              </div>

              <Row>
                <Col xl="9">
                  <div className="bg-white px-2 rounded">
                    <div className="container pt-3">
                      <Heading text="Hangar Detail" customClass="m-0 px-0 pt-0" />
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {(hangar_detail?.images || []).slice(0, 3).map((image, index) => (
                          <img
                            key={index}
                            style={{
                              width: "250px",
                              height: '180px',
                              borderRadius: "4px",
                              marginRight: "2px",
                              border: '1px solid #d7d7d7',
                              objectFit: "cover",
                            }}
                            src={getImageUrl(image)}
                            alt={`hangar detail img ${index + 1}`}
                          />
                        ))}
                      </div>

                      <div className="table-responsive pt-3" style={{ overflowX: "auto", width: "100%" }}>
                        <table className="table table-white" style={{ minWidth: "800px" }}>
                          <thead>
                            <tr>
                              <th scope="col" className="px-3" style={{ color: "#737373", fontWeight: "500", whiteSpace: "nowrap" }}>Hangar Id</th>
                              <th scope="col" className="px-3" style={{ color: "#737373", fontWeight: "500", whiteSpace: "nowrap" }}>Hangar name</th>
                              <th scope="col" className="px-3" style={{ color: "#737373", fontWeight: "500", whiteSpace: "nowrap" }}>Address</th>
                              {dynamic_check && <th scope="col" className="px-3" style={{ color: "#737373", fontWeight: "500", whiteSpace: "nowrap" }}>Dynamic Hangar</th>}
                              <th scope="col" className="px-3" style={{ color: "#737373", fontWeight: "500", whiteSpace: "nowrap" }}>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="px-3" style={{ whiteSpace: "nowrap", color: "black", fontWeight: "500" }}>{hangar_detail?.id}</td>
                              <td className="px-3" style={{ color: "black", fontWeight: "500", whiteSpace: "nowrap" }}>
                                {hangar_detail?.title}
                              </td>
                              <td className="px-3" style={{ color: "black", fontWeight: "500", whiteSpace: "nowrap" }}>
                                {hangar_detail?.address}
                              </td>
                              {dynamic_check && <td className="px-3" style={{ color: "black", fontWeight: "500", whiteSpace: "nowrap" }}>
                                {"True"}
                              </td>}
                              <td className="px-3" style={{ color: "black", fontWeight: "500", whiteSpace: "nowrap" }}>
                                {dynamic_check ? (
                                  <>
                                    ${rental_type_monthly
                                      ? (hangar_per_month * plane_area_sq_ft).toFixed(2)
                                      : (hangar_per_night * plane_area_sq_ft).toFixed(2)}
                                    {rental_type_monthly ? " per month" : " per day"}
                                  </>
                                ) : (
                                  <>
                                    ${rental_type_monthly ? hangar_per_month : hangar_per_night} {rental_type_monthly ? "per month" : "per day"}
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <Detail
                    statusInfo={{ subscription_status, order_status }}
                    ownerInfo={{ hangar_owner_detail, plane_owner_detail }}
                    paymentInfo={{ payment_intent, tax, serviceFee, credit, total, reservation_amount }}
                    bookingInfo={{ check_in, check_in_without_format, check_out, check_out_without_format, rental_type, rental_type_daily, rental_type_monthly, created_at, day }}
                    subscriptionData={subscriptionData}
                    cancel_at_end={cancel_at_end}
                    premium_amenities_amount={premium_amenities_amount}
                    DYNAMIC_CHECK={dynamic_check}
                    aircraftInfo={{ tail_number, aircraft_name }}
                    refundedData={refundedData}
                  />
                </Col>

                <Col xl="3" className="p-0">
                  <ClientBillingDetail
                    setRefundModel={setRefundModel}
                    refundedData={refundedData}
                    hangar_owner_detail={hangar_owner_detail}
                    plane_owner_detail={plane_owner_detail}
                    order_status={order_status}
                    cancelledData={cancelledData}
                    cancellation_reason={cancellation_reason}
                    handleCancellation={handleCancellation}
                    serviceFee={serviceFee}
                    totalAmount={total || 0}
                    rental_type={rental_type}
                    cancel_at_end={cancel_at_end}
                    check_in={check_in}
                    check_out={check_out}
                    loading={loading}
                    payment_intent={payment_intent}
                    last_payment_amount={subscriptionData?.history?.[0]?.total || 0}
                    subscription_status={subscription_status}
                    {...{ paymentIntentDetails, setPaymentIntentDetails, invoiceDetail }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReservationDetail