import React, { useEffect, useState } from "react";
import { supabase } from "pages/Utility/supabase";
import DefaultLoading from "common/loading";
import { Button, Container, Card, CardBody } from "reactstrap";
import {
  OrderId, Total, Date, StartDate,
  EndDate,
  Owner,
  RentalType,
  HangarOwnerTitle,
  PlaneOwnerTitle,
  CancelAtEnd,
} from "../Dashboard-Orders/LatestRequestsCol";
import { TABLES } from "pages/Utility/constants";
import Alerts from "common/Alerts/Alert";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import TableContainerPagination from "components/Common/TableContainerPagination";
import fetchPaginatedData from "utils/fetchPaginatedData";

const Reservations = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    canNextPage: false,
    canPreviousPage: false
  });

  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  };
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES);

  const RESERVATIONS_QUERY_FIELDS = `*, hangar_id (id, slug, title, address, instructions, price_per_night, price_per_month), hangar_owner (id, full_name), user_id (stripe_customer, id, full_name), stripe_payment_intent (*), review (*)`;


  document.title = "Reservations | HangarDirect";

  const columns = [
    {
      Header: "Reservation Id",
      accessor: "id",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />;
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      disableFilters: true,
      Cell: cellProps => {
        return <Date {...cellProps} />;
      },
    },
    {
      Header: "Hangar Owner",
      accessor: "hangar_owner.full_name",
      disableFilters: true,
      Cell: cellProps => {
        return <HangarOwnerTitle {...cellProps} />;
      },
    },
    {
      Header: "Plane Owner",
      accessor: "user_id.full_name",
      disableFilters: true,
      Cell: cellProps => {
        return <PlaneOwnerTitle {...cellProps} />;
      },
    },
    {
      Header: "Rental Type",
      accessor: "rental_type",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <RentalType {...cellProps} />;
      },
    },
    {
      Header: "Status",
      accessor: "order_status",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Owner {...cellProps} />;
      },
    },
    {
      Header: "Check In",
      accessor: "check_in",
      Cell: cellProps => {
        return <StartDate {...cellProps} />;
      },
      disableFilters: true,
    },
    {
      Header: "Check Out",
      accessor: "check_out",
      Cell: cellProps => {
        return <EndDate {...cellProps} />;
      },
      disableFilters: true,
    },
    {
      Header: "Ending on Check Out",
      accessor: "cancel_at_end",
      Cell: cellProps => {
        return <CancelAtEnd {...cellProps} />;
      },
      disableFilters: true,
    },
    {
      Header: "Total",
      accessor: "total_Amount",
      Cell: cellProps => {
        return <Total {...cellProps} />;
      },
      disableFilters: true,
    },
    {
      Header: "View Details",
      disableFilters: true,
      accessor: row => {
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              history.push(`/invoices-detail/${row.id}`);
            }}
          >
            View Details
          </Button>
        );
      },
    },
  ];

  const getReservationData = async (parameter) => {
    try {
      setLoading(true);
      let defaultFilters = [];

      if (parameter?.filter?.length) {
        const result = await supabase
          .from(TABLES.RESERVATIONS)
          .select(
            RESERVATIONS_QUERY_FIELDS,
            { count: 'exact' }
          )
          .or(
            `full_name.ilike.%${parameter.filter}%`,
            { referencedTable: "hangar_owner" }
          )
          .or(
            `rental_type.ilike.%${parameter.filter}%`
          )
          .range(
            (parameter?.page - 1) * (parameter?.pageSize || 10),
            (parameter?.page * (parameter?.pageSize || 10)) - 1
          )
          .order('created_at', { ascending: false });

        if (result.error) {
          toast.error(result.error.message);
          return;
        }

        const { data, count } = result;

        if (data?.length) {
          setData(data);
          setPagination({
            currentPage: parameter?.page || 1,
            pageSize: parameter?.pageSize || 10,
            totalCount: count || 0,
            totalPages: Math.ceil((count || 0) / (parameter?.pageSize || 10)),
            canPreviousPage: (parameter?.page || 1) > 1,
            canNextPage: (parameter?.page || 1) < Math.ceil((count || 0) / (parameter?.pageSize || 10))
          });
        } else {
          setData([]);
          setPagination({
            ...pagination,
            totalCount: 0,
            totalPages: 0,
            canNextPage: false,
            canPreviousPage: false
          });
        }
        setLoading(false);
        return;
      }

      const result = await fetchPaginatedData({
        table: TABLES.RESERVATIONS,
        select: RESERVATIONS_QUERY_FIELDS,
        page: parameter?.page || pagination?.currentPage || 1,
        pageSize: parameter?.pageSize || pagination?.pageSize || 10,
        orderBy: 'created_at',
        ascending: false,
        filters: defaultFilters,
      });

      if (result.data?.length) {
        setData(result.data);
        setPagination({
          ...pagination,
          ...result.pagination,
          canPreviousPage: result.pagination.currentPage > 1,
          canNextPage: result.pagination.currentPage < result.pagination.totalPages
        });
      } else {
        setData([]);
        setPagination({
          ...pagination,
          totalCount: 0,
          totalPages: 0,
          canNextPage: false,
          canPreviousPage: false
        });
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
      toast.error(error.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReservationData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content position-relative" style={{ minHeight: "400px" }}>
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}

          {loading && <DefaultLoading style={{ position: "absolute", width: "90%", height: "100%", minHeight: "400px" }} />}

          <div className="mb-4 h4 card-title">Reservations</div>
          <Card>
            <CardBody>
              <TableContainerPagination
                columns={columns}
                data={data}
                isSortBy={false}
                isGlobalFilter={true}
                isAddUserList={false}
                isFilter={false}
                customPageSize={10}
                pagination={pagination}
                refetchData={getReservationData}
                className="custom-header-css"
                rowClass="cursor-auto-imp"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reservations;