import moment from "moment";
import { LEASE_TYPES, STATUS } from "pages/Utility/constants";

export const getOrderStatus = (params) => {
  const now = moment();
  const checkIn = moment(params.checkIn);
  const checkOut = moment(params.checkOut);

  const formatDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };

  // For cancelled/refunded/rejected orders
  if (
    params.orderStatus === STATUS.ORDER_REFUNDED ||
    params.orderStatus === STATUS.ORDER_CANCELED ||
    params.orderStatus === STATUS.ORDER_REJECTED
  ) {
    const isRejected = params.orderStatus === STATUS.ORDER_REJECTED;
    return {
      text: isRejected ? "Rejected" : "Cancelled",
      textColor: "text-danger",
      bgColor: "bg-danger bg-opacity-25",
      title: isRejected ? "Rejected" : "Cancelled",
      description: isRejected
        ? "The host has rejected this reservation request. The plane owner has been notified of this decision."
        : "This reservation has been cancelled by the plane owner. No further action is required."
    };
  }

  // For auto-cancelled orders
  if (params.orderStatus === STATUS.ORDER_AUTO_CANCEL) {
    return {
      text: "Auto Cancelled",
      textColor: "text-danger",
      bgColor: "bg-danger bg-opacity-25",
      title: "Auto Cancelled",
      description: "This reservation was automatically cancelled because the host did not accept it within the required timeframe."
    };
  }

  // For in-process/pending orders
  if (params.orderStatus === STATUS.ORDER_INPROCESS) {
    return {
      text: "Pending",
      textColor: "text-warning",
      bgColor: "bg-warning bg-opacity-25",
      title: "Pending",
      description: "This reservation request is awaiting approval from the host. No payment has been processed yet."
    };
  }

  // For approved orders
  if (params.orderStatus === STATUS.ORDER_APPROVED) {
    const isSubscriptionCompleted =
      params.subscriptionStatus === "canceled" || params.cancelAtEnd;

    if (checkOut.isBefore(now) || isSubscriptionCompleted) {
      const endedText = params?.rentalType == LEASE_TYPES.DAILY ? "Completed" : "Ended";

      // Different description based on whether checkout date is in the past or future
      let description = "";
      if (checkOut.isBefore(now)) {
        description = `This reservation was successfully completed on ${formatDate(params.checkOut)}. All payments have been processed and distributed.`;
      } else {
        description = `This reservation has ended early due to cancellation. The original checkout date was scheduled for ${formatDate(params.checkOut)}.`;
      }

      return {
        text: endedText,
        textColor: "text-success",
        bgColor: "bg-success bg-opacity-25",
        title: endedText,
        description: description
      };
    }

    // If order is currently active (between checkin and checkout dates)
    if (checkIn.isBefore(now) && checkOut.isAfter(now)) {
      return {
        text: "Ongoing",
        textColor: "text-primary",
        bgColor: "bg-info bg-opacity-25",
        title: "Ongoing",
        description:
          params.rentalType === LEASE_TYPES.DAILY
            ? `This reservation is currently active. The guest has checked in and is scheduled to check out on ${formatDate(params.checkOut)}.`
            : "This reservation is currently active on a monthly basis. The guest has checked in and will continue until a cancellation is processed."
      };
    }

    // If order is approved but not yet started
    return {
      text: "Confirmed",
      textColor: "text-success",
      bgColor: "bg-info bg-opacity-25",
      title: "Confirmed",
      description: `This reservation has been approved by the host. The guest's check-in is scheduled for ${formatDate(params.checkIn)}.`
    };
  }

  // Default/fallback status
  return {
    text: "Unknown",
    textColor: "#74788d",
    bgColor: "bg-secondary bg-opacity-25",
    title: "Unknown Status",
    description: "The status of this reservation cannot be determined due to missing or invalid information."
  };
};