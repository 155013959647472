import React, { useEffect, useState } from "react";
import { supabase } from "pages/Utility/supabase";
import DefaultLoading from "common/loading";
import { Container, Card, CardBody } from "reactstrap";
import TableContainerPagination from "components/Common/TableContainerPagination";
import { toast } from "react-toastify";
import { DATE_FORMATS, STATUS, TABLES } from "pages/Utility/constants";
import moment from "moment";
import Swal from "sweetalert2";
import { initiateFullPaymentRefund } from "pages/Utility/stripe_functions";

const RefundRequests = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
        totalPages: 0,
        canNextPage: false,
        canPreviousPage: false
    });

    const handleApproveRefund = async (refundData) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure you want to approve this refund?',
                text: "This action cannot be undone!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, approve!'
            });

            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    didOpen: () => Swal.showLoading(),
                    allowOutsideClick: false
                });

                const paymentIntent = refundData.transaction?.stripe_payment_intent;
                if (!paymentIntent) {
                    throw new Error("Stripe payment intent is missing from transaction data");
                }

                const refundResult = await initiateFullPaymentRefund(paymentIntent);

                const { error } = await supabase
                    .from(TABLES.REFUND_REQUEST)
                    .update({ status: STATUS.REFUND_REQUEST_APPROVED })
                    .eq('id', refundData.id);

                if (error) throw error;

                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Refund has been approved successfully.',
                });

                getRefundRequests({
                    page: pagination.currentPage,
                    pageSize: pagination.pageSize
                });
            }
        } catch (error) {
            console.error("Error in handleApproveRefund:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Something went wrong!'
            });
        }
    };

    const handleRejectRefund = async (refundId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure you want to reject this refund?',
                text: "This action cannot be undone!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, reject!'
            });

            if (result.isConfirmed) {
                const { error } = await supabase
                    .from(TABLES.REFUND_REQUEST)
                    .update({ status: STATUS.REFUND_REQUEST_REJECTED })
                    .eq('id', refundId);

                if (error) throw error;

                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Refund has been rejected successfully.',
                });

                getRefundRequests({
                    page: pagination.currentPage,
                    pageSize: pagination.pageSize
                });
            }
        } catch (error) {
            console.error("Error in handleRejectRefund:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Something went wrong!'
            });
        }
    };

    const columns = [
        {
            Header: "Refund Id",
            accessor: "id",
            Cell: ({ value }) => <span className="font-medium">{value}</span>
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }) => (
                <span>{moment(value).format(DATE_FORMATS.LONG)}</span>
            )
        },
        {
            Header: "Reservation Id",
            accessor: "reservation",
            Cell: ({ value }) => <span className="text-blue-600">{value}</span>
        },
        {
            Header: "Transaction Id",
            accessor: "transaction.id",
            Cell: ({ value }) => <span className="text-blue-600">{value}</span>
        },
        {
            Header: "Reason",
            accessor: "reason",
            Cell: ({ value }) => <span>{value}</span>
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => {
                let statusText = "";
                let statusClass = "";

                switch (value) {
                    case STATUS.REFUND_REQUEST_IN_REVIEW:
                        statusText = "In Review";
                        statusClass = "badge bg-warning";
                        break;
                    case STATUS.REFUND_REQUEST_APPROVED:
                        statusText = "Approved";
                        statusClass = "badge bg-success";
                        break;
                    case STATUS.REFUND_REQUEST_REJECTED:
                        statusText = "Rejected";
                        statusClass = "badge bg-danger";
                        break;
                    default:
                        statusText = "Unknown";
                        statusClass = "badge bg-secondary";
                }

                return (
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${statusClass}`}>
                        {statusText}
                    </span>
                );
            }
        },
        {
            Header: "Action",
            disableSortBy: true,
            accessor: ({ id, status, transaction }) => {
                const isDisabled = status === STATUS.REFUND_REQUEST_APPROVED || status === STATUS.REFUND_REQUEST_REJECTED;
                
                if (isDisabled) {
                    return <span>---</span>;
                }
                
                return (
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => handleApproveRefund({ id, transaction })}
                        >
                            Approve
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => handleRejectRefund(id)}
                        >
                            Reject
                        </button>
                    </div>
                );
            }
        }
    ];

    const getRefundRequests = async (parameter) => {
        try {
            setLoading(true);
            
            const page = parameter?.page || pagination.currentPage || 1;
            const pageSize = parameter?.pageSize || pagination.pageSize || 10;
            const startIndex = (page - 1) * pageSize;
            const endIndex = page * pageSize - 1;
            
            let query = supabase
                .from(TABLES.REFUND_REQUEST)
                .select(
                    '*, transaction(id, stripe_payment_intent)',
                    { count: 'exact' }
                );
                
            if (parameter?.filter) {
                query = query.or(`reason.ilike.%${parameter.filter}%`);
            }
            
            // Apply pagination
            const { data, error, count } = await query
                .range(startIndex, endIndex)
                .order('created_at', { ascending: false });

            if (error) {
                toast.error(error.message);
                return;
            }

            if (data?.length) {
                setData(data);
                setPagination({
                    currentPage: page,
                    pageSize: pageSize,
                    totalCount: count || 0,
                    totalPages: Math.ceil((count || 0) / pageSize),
                    canPreviousPage: page > 1,
                    canNextPage: page < Math.ceil((count || 0) / pageSize)
                });
            } else {
                setData([]);
                setPagination({
                    ...pagination,
                    currentPage: page,
                    pageSize: pageSize,
                    totalCount: 0,
                    totalPages: 0,
                    canNextPage: false,
                    canPreviousPage: false
                });
            }
        } catch (error) {
            console.error("Error fetching refund requests:", error.message);
            toast.error(error.message);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRefundRequests();
    }, []);

    return (
        <div className="page-content">
            <Container fluid>
                <div className="mb-4 text-xl font-semibold">Refund Requests</div>

                <Card>
                    <CardBody>
                        {loading && (
                            <DefaultLoading className="absolute w-full h-full min-h-96" />
                        )}

                        <TableContainerPagination
                            columns={columns}
                            data={data}
                            isSortBy={false}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            isFilter={false}
                            customPageSize={10}
                            pagination={pagination}
                            refetchData={getRefundRequests}
                            className="custom-header-css"
                            rowClass="cursor-auto-imp"
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default RefundRequests;