import React, { useState, useEffect, useRef } from 'react';

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      d="M6.139 9.75h.722V5.778H6.14zm.361-5a.43.43 0 0 0 .317-.128.43.43 0 0 0 .127-.317.43.43 0 0 0-.128-.316.43.43 0 0 0-.316-.128.436.436 0 0 0-.444.445q0 .189.128.316a.43.43 0 0 0 .316.128M6.502 13q-1.348 0-2.535-.511a6.6 6.6 0 0 1-2.065-1.39 6.6 6.6 0 0 1-1.39-2.063A6.3 6.3 0 0 1 0 6.502q0-1.35.512-2.535.51-1.186 1.387-2.065A6.5 6.5 0 0 1 3.963.512 6.3 6.3 0 0 1 6.498 0q1.347 0 2.535.512A6.6 6.6 0 0 1 11.098 1.9a6.6 6.6 0 0 1 1.39 2.064Q13 5.149 13 6.498a6.3 6.3 0 0 1-.511 2.535 6.6 6.6 0 0 1-1.39 2.065 6.6 6.6 0 0 1-2.063 1.39A6.3 6.3 0 0 1 6.502 13m-.002-.722q2.42 0 4.099-1.68 1.68-1.678 1.679-4.098t-1.68-4.099Q8.92.721 6.5.722t-4.099 1.68Q.721 4.08.722 6.5t1.68 4.099T6.5 12.278"
      fill="#000"
      fillOpacity={0.7}
    />
  </svg>
);

const InfoBadge = ({ 
  className, 
  title, 
  description,
  tooltipWidth = '250px',
  tooltipTop = '-88px',
  tooltipRight = '8px',
  tooltipZIndex = 1000,
  tooltipShadow = '0px 1px 4px 0px rgba(0,0,0,0.25)'
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ 
    top: tooltipTop, 
    right: tooltipRight 
  });
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleTooltipPosition = () => {
      if (showTooltip && tooltipRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        
        const wouldGoAbove = containerRect.top - tooltipRect.height < 0;
        const wouldGoBelow = containerRect.bottom + tooltipRect.height > windowHeight;
        
        setTooltipPosition({
          top: wouldGoAbove ? '24px' : tooltipTop,
          right: tooltipRight
        });
      }
    };

    handleTooltipPosition();
    window.addEventListener('scroll', handleTooltipPosition);
    window.addEventListener('resize', handleTooltipPosition);

    return () => {
      window.removeEventListener('scroll', handleTooltipPosition);
      window.removeEventListener('resize', handleTooltipPosition);
    };
  }, [showTooltip, tooltipTop, tooltipRight]);

  return (
    <div className="position-relative d-inline-flex align-items-center" ref={containerRef}>
      <div className={`d-flex align-items-center ${className}`}>
        <span>{title}</span>
        {title !== "-" && (
          <div
            className="position-relative ms-1"
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <InfoIcon />

            {showTooltip && (
              <div 
                ref={tooltipRef}
                className="position-absolute bg-white p-2 rounded"
                style={{
                  width: tooltipWidth,
                  top: tooltipPosition.top,
                  right: tooltipPosition.right,
                  zIndex: tooltipZIndex,
                  boxShadow: tooltipShadow,
                  transition: 'top 0.2s ease-in-out'
                }}
              >
                <div className="fw-bold mb-1">{title}</div>
                <div className="text-secondary" style={{fontSize: "11px"}}>{description}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBadge;