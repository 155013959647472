import { toast } from "react-toastify"

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
const stripe = require("stripe")(KEY)

export const fundsTransfer = async props => {
  const { stripeAcc, amount, transfer_id, stripePayoutMethodId } = props;
  try {

    if (!transfer_id) {
      // If payout is attempting first time
      const transfer = await stripe.transfers.create({
        amount,
        currency: "usd",
        destination: stripeAcc,
      });

      if (transfer?.id) {
        const payoutOptions = {
          amount,
          currency: "usd",
        };

        if (stripePayoutMethodId) {
          payoutOptions.destination = stripePayoutMethodId;
        }

        const payout = await stripe.payouts.create(
          payoutOptions,
          {
            stripeAccount: stripeAcc,
          }
        );

        if (payout?.id) {
          return { transferID: transfer.id, payoutID: payout.id };
        } else {
          return { transferID: transfer.id };
        }
      }
    } else {
      // For if transfer is completed but payout pending
      const payoutOptions = {
        amount,
        currency: "usd",
      };

      if (stripePayoutMethodId) {
        payoutOptions.destination = stripePayoutMethodId;
      }

      const payout = await stripe.payouts.create(
        payoutOptions,
        {
          stripeAccount: stripeAcc,
        }
      );

      if (payout?.id) {
        return { transferID: transfer_id, payoutID: payout.id };
      }
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error, "Error11")
  }
};


export const checkPaymentIntentStatus = async (paymentIntentId) => {
  try {
    if (!paymentIntentId) {
      throw new Error("Payment intent ID is required");
    }

    const paymentIntent = await stripe.paymentIntents.retrieve(paymentIntentId);

    const applyStripeFee = paymentIntent.status === "succeeded";

    return {
      success: true,
      status: paymentIntent.status,
      applyStripeFee,
      amount: paymentIntent.amount / 100,
      paymentIntent
    };
  } catch (error) {
    console.error("Error retrieving payment intent:", error);
    return {
      success: false,
      error: error.message,
      applyStripeFee: false
    };
  }
};

export const calculateStripeFee = (amount) => {
  return (amount * 0.029) + 0.30;
};

export const refundPayment = async ({ invoiceDetail, reasonTxt, paymentIntentDetails, cancellationData, isMonthlyRefund }) => {
  try {

    const { refund_type, cancellation_reason, refund_amount: amountForRefund } = cancellationData;

    //  =========== If refund for subscription start ====================
    if (isMonthlyRefund) {
      const subscriptionId = invoiceDetail?.stripe_subscription_id;

      if (!subscriptionId) {
        throw new Error("Subscription id not found");
      }
      const subscription = await stripe.subscriptions.retrieve(subscriptionId);
      const latestInvoiceId = subscription.latest_invoice;
      if (!latestInvoiceId) {
        throw new Error("Subscription invoice not found");
      }

      const invoice = await stripe.invoices.retrieve(latestInvoiceId);

      if (!invoice.payment_intent) {
        throw new Error("No payment intent found for this invoice");
      }

      // Refund the last payment
      const refund = await stripe.refunds.create({
        payment_intent: invoice.payment_intent,
      });
      return {
        success: true, data: refund
      }
    }
    //  =========== If refund for subscription close ====================


    //  =========== Other cases ====================


    const isRequireCapture = paymentIntentDetails?.status == "requires_capture";
    const isForFullRefund = refund_type == "full";


    const paymentIntentId = paymentIntentDetails?.id;

    if (!paymentIntentId) {
      throw new Error("Payment intent ID is required for refund");
    }


    let refund;

    // Handling Refunding full amount
    if (isForFullRefund) {

      if (isRequireCapture) {
        refund = await stripe.paymentIntents.cancel(paymentIntentId);
      }
      // If payment intent is already captured then refund
      else {
        refund = await stripe.refunds.create({
          payment_intent: paymentIntentId,
        });
      }

    }

    // Handling partial refund 
    if (!isForFullRefund) {
      if (isRequireCapture) await stripe.paymentIntents.capture(paymentIntentId);
      refund = await stripe.refunds.create({
        payment_intent: paymentIntentId,
        amount: Math.round(amountForRefund * 100),
      });
    }

    return {
      success: true,
      refund,
      refundAmount: amountForRefund
    };
  } catch (error) {
    console.error("Error processing refund:", error);
    toast.error(`Refund failed: ${error.message}`);
    return {
      success: false,
      error: error.message
    };
  }
};


export const cancelPaymentIntentMethod = async (payment_intent) => {
  try {

    const response = await stripe.paymentIntents.cancel(payment_intent);
    return response

  } catch (error) {
    if (error?.message.includes("it has a status of canceled"))
      return { already_cancelled: true, refunded: true }

    throw error
  }
}

export const createStripeProductAndPrice = async ({ name, amount, interval = "month", tax_code }) => {
  try {
    const product_object = {
      name: `Promotion Package: ${name}`,
    }
    if (tax_code) product_object.tax_code = tax_code;

    const product = await stripe.products.create(product_object);

    const price = await stripe.prices.create({
      product: product.id,
      unit_amount: (amount * 100),
      currency: 'usd',
      recurring: { interval },
      tax_behavior: "inclusive"
    })

    await stripe.products.update(product.id, {
      default_price: price.id
    });

    if (product && price) {
      return { success: true, data: { product, price } };
    }

  } catch (error) {
    return { success: false, data: null, message: error?.message || error };
  }
}

export const updateStripeProductPrice = async ({ productId, amount, interval = "month" }) => {
  try {

    const price = await stripe.prices.create({
      product: productId,
      unit_amount: (amount * 100),
      currency: 'usd',
      recurring: { interval },
    })

    await stripe.products.update(productId, {
      default_price: price.id
    });

    if (price) {
      return { success: true, data: price };
    }

  } catch (error) {
    return { success: false, data: null, message: error?.message || error };
  }
}

export const cancelStripeSubscription = async ({ subscription_id, cancelNow }) => {
  try {
    if (!subscription_id) throw new Error("Subscription ID is required");
    const subscription_cancel = cancelNow ? await stripe.subscriptions.cancel(
      subscription_id
    ) : await stripe.subscriptions.update(subscription_id, {
      cancel_at_period_end: true,
    });

    return { success: true, data: subscription_cancel }
  } catch (error) {
    return { success: false, error: error, message: error?.message || error };
  }
}

export const initiateFullPaymentRefund = async (payment_intent_id) => {
  try {
    if (!payment_intent_id) {
      throw new Error("Payment intent is required");
    }

    // Step 1: Retrieve payment intent
    const paymentIntent = await stripe.paymentIntents.retrieve(payment_intent_id);

    let refund;
    // Step 2: Refund or Cancel based on status
    if (paymentIntent.status === "requires_capture") {
      refund = await stripe.paymentIntents.cancel(payment_intent_id);
    } else {
      refund = await stripe.refunds.create({ payment_intent: payment_intent_id });
    }

    return { success: true, refund };
  } catch (error) {
    console.error("Error processing refund:", error);
    return { success: false, error: error.message };
  }
};