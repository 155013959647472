import React from "react"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { getOrderStatus } from "utils/orderStatusHelper"
import { LEASE_TYPES } from "pages/Utility/constants"
import InfoBadge from "components/Invoice/InfoIcon"


const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <div className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : "-"}
    </div>
  )
}

const PlaneOwnerTitle = cell => {
  return cell.row.original?.user_id?.full_name ? cell.row.original?.user_id?.full_name : "-"
}
const HangarOwnerTitle = cell => {
  return cell.row.original?.hangar_owner?.full_name ? cell.row.original?.hangar_owner?.full_name : "-"
}
const Total = cell => {
  return (
    <div>
      <span style={{ fontWeight: "500" }}>$</span>
      {cell.cell.row.original.total
        ? (+cell.cell.row.original.total).toFixed(2)
        : "-"}
    </div>
  )
}

const Date = cell => {
  const date = moment(cell.row.original.created_at).format("DD-MM-YYYY")
  return date ? date : "-"
}
const StartDate = cell => {
  const date = moment(cell.row.original.check_in).format("DD-MM-YYYY")
  return date ? date : "-"
}
const EndDate = cell => {
  const date = moment(cell.row.original.check_out).format("DD-MM-YYYY")
  return date ? date : "-"
}
const CancelAtEnd = cell => {
  const rowData = cell.row.original;

  if (rowData.rental_type === LEASE_TYPES.DAILY) {
    return "True";
  }

  return rowData.cancel_at_end ? "True" : "False";
}
const RentalType = cell => <span style={{ textTransform: 'capitalize' }}>{cell?.row?.original?.rental_type}</span>

const Owner = (cell) => {
  const rowData = cell.row.original;
  const isFirstRow = cell.row.index === 0;

  const params = {
    orderStatus: rowData.order_status,
    checkIn: rowData.check_in,
    checkOut: rowData.check_out,
    rentalType: rowData.rental_type,
    paymentStatus: rowData.stripe_payment_intent?.payment_status,
    subscriptionStatus: rowData.stripe_subscription_status,
    cancelAtEnd: rowData.cancel_at_end,
  };
  
  const statusDisplay = getOrderStatus(params);

  return (
    <div className={`d-flex justify-content-between px-2`}>
      <InfoBadge
        className={`${statusDisplay.bgColor} ${statusDisplay.textColor} px-2 py-1 rounded-2`}
        title={statusDisplay.text}
        tooltipTop = {`${isFirstRow ? "-58px" : "-88px"}`}
        tooltipRight = {`${isFirstRow ? "14px" : "8px"}`}
        description={statusDisplay.description || "Status information not available"}
      />
    </div>
  );
};

export default Owner;


const Financial = cell => {
  return (
    <div style={{ color: "#34C38F" }}>
      <span
        style={{ fontWeight: "500" }}
        className="bg-info bg-opacity-25 px-2 rounded-2"
      >
        {cell.row.original?.paymentStatus
          ? cell.row.original?.paymentStatus
          : ""}
      </span>
    </div>
  )
}

const Status = cell => {
  return (
    <Badge
      style={{
        textTransform: "capitalize",
        fontSize: "14px",
        padding: "5px 10px",
      }}
      className={
        "badge-soft-" +
        (cell.tabIndex === 1
          ? "warning"
          : cell.tabIndex === 2
            ? "success"
            : cell.tabIndex === 3
              ? "danger"
              : "info")
      }
    >
      {cell.tabIndex === 1
        ? "In Review"
        : cell.tabIndex === 2
          ? "Approved"
          : cell.tabIndex === 3
            ? "Rejected"
            : "Deleted!"}
    </Badge>
  )
}

export {
  CheckBox,
  RentalType,
  OrderId,
  PlaneOwnerTitle,
  HangarOwnerTitle,
  Owner,
  Financial,
  Status,
  Date,
  StartDate,
  EndDate,
  Total,
  CancelAtEnd
}
