import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, Input, Spinner } from "reactstrap";
import { STATUS, LEASE_TYPES, DATE_FORMATS } from "pages/Utility/constants";
import { checkPaymentIntentStatus, calculateStripeFee } from "pages/Utility/stripe_functions";
import moment from "moment";

const CancellationRefundModal = ({
  isOpen,
  toggle,
  handleCancellation,
  totalAmount = 0,
  isRefundMode = false,
  isMonthlyRefund = false,
  setIsMonthlyRefund,
  paymentIntentId = null,
  serviceFee = 0,
  rental_type = "",
  order_status = 0,
  check_out = "",
  isPendingStatus,
  subscription_status = "",
  cancel_at_end = false,
  last_payment_amount,
  paymentIntentDetails,
  setPaymentIntentDetails,
  isSubscriptionCanceled,
  canEndAndRefundMonthly,
  isOrderApproved,
  isCanceledStatus
}) => {
  const [cancelledBy, setCancelledBy] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [refundType, setRefundType] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [selectedPartialOption, setSelectedPartialOption] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [stripeFee, setStripeFee] = useState(0);
  const [applyStripeFee, setApplyStripeFee] = useState(true);
  const [fullRefundAmount, setFullRefundAmount] = useState(totalAmount);
  const [subscriptionEndType, setSubscriptionEndType] = useState("");

  // if it's monthly rental
  const rental_type_monthly = rental_type === LEASE_TYPES.MONTHLY;

  useEffect(() => {
    if (isOpen && isRefundMode && paymentIntentId) {
      fetchPaymentIntentDetails();
    } else if (isOpen) {
      const calculatedFee = calculateStripeFee(totalAmount);
      setStripeFee(calculatedFee);
      setFullRefundAmount(totalAmount);
      setApplyStripeFee(true);
    }
  }, [isOpen, paymentIntentId, totalAmount]);

  const fetchPaymentIntentDetails = async () => {
    setLoading(true);
    try {
      const result = await checkPaymentIntentStatus(paymentIntentId);

      if (result.success) {
        const calculatedStripeFee = result.applyStripeFee ? calculateStripeFee(totalAmount) : 0;
        setApplyStripeFee(result.applyStripeFee);
        setStripeFee(calculatedStripeFee);
        setFullRefundAmount(totalAmount);
        setPaymentIntentDetails(result.paymentIntent);
      } else {
        console.error("Error fetching payment intent:", result.error);
        const calculatedFee = calculateStripeFee(totalAmount);
        setStripeFee(calculatedFee);
        setFullRefundAmount(totalAmount);
        setApplyStripeFee(true);
      }
    } catch (error) {
      console.error("Error in payment intent check:", error);
    } finally {
      setLoading(false);
    }
  };

  // Calculate partial refund options
  const getPartialRefundOptions = () => {
    return {
      minusBothFees: {
        label: "Refund excluding Service Fee and Stripe Fee",
        value: totalAmount - serviceFee - stripeFee,
        applyServiceFee: true,
        applyStripeFee: true
      },
      minusStripeFee: {
        label: "Refund excluding Stripe Fee",
        value: totalAmount - stripeFee,
        applyServiceFee: false,
        applyStripeFee: true
      },
      custom: {
        label: "Custom Amount",
        value: 0,
        applyServiceFee: false,
        applyStripeFee: false
      }
    };
  };

  const handlePartialOptionSelect = (option) => {
    setSelectedPartialOption(option);
    const options = getPartialRefundOptions();

    if (option === "custom") {
      setRefundAmount("");
    } else {
      setRefundAmount(options[option].value.toFixed(2));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Only validate who requested and reason for non-monthly or pending monthly cases
    // Skip validation for canceled status in refund-only mode
    if (!(rental_type_monthly && !isPendingStatus) && !isCanceledStatus) {
      if (!cancelledBy) newErrors.cancelledBy = "Please select who requested cancellation";
      if (!cancellationReason.trim()) newErrors.cancellationReason = "Please provide a cancellation reason";
    }

    // Monthly-specific validation - don't check for subscriptionEndType if isMonthlyRefund is true
    if (rental_type_monthly && !isPendingStatus && !isMonthlyRefund) {
      if (!subscriptionEndType) newErrors.subscriptionEndType = "Please select when to end the subscription";
    }

    // If monthly refund is checked, make sure refund type is selected
    if (isMonthlyRefund && refundType !== "last_payment") {
      newErrors.refundType = "Please confirm the last payment refund";
    }

    // Refund mode validations
    if (isRefundMode) {
      if (!refundType) newErrors.refundType = "Please select a refund type";

      if (refundType === "partial") {
        if (!selectedPartialOption && (!refundAmount || parseFloat(refundAmount) <= 0)) {
          newErrors.refundAmount = "Please select a refund option or enter a valid amount";
        }

        if (refundAmount && parseFloat(refundAmount) > totalAmount) {
          newErrors.refundAmount = "Refund amount cannot exceed total amount";
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      // For canceled status in refund-only mode
      if (isCanceledStatus && isRefundMode) {
        const data = {
          // Keep the order_status as canceled
          order_status: STATUS.ORDER_CANCELED,
          refund_type: refundType
        };

        if (refundType === "full") {
          data.refund_amount = fullRefundAmount;
          data.apply_stripe_fee = false;
          data.apply_service_fee = false;
        } else if (refundType === "partial") {
          if (selectedPartialOption && selectedPartialOption !== "custom") {
            const options = getPartialRefundOptions();
            const option = options[selectedPartialOption];
            data.refund_amount = option.value;
            data.apply_stripe_fee = option.applyStripeFee;
            data.apply_service_fee = option.applyServiceFee;
          } else {
            data.refund_amount = parseFloat(refundAmount);
            data.apply_stripe_fee = false;
            data.apply_service_fee = false;
          }
        }

        handleCancellation(data);
        toggle();
        return;
      }

      // For monthly rental with non-pending status
      if (rental_type_monthly && !isPendingStatus) {
        const data = {
          order_status: STATUS.ORDER_CANCELED,
        };

        if (isMonthlyRefund) {
          data.end_type = "immediate";
          data.refund_type = "last_payment";
          data.refund_amount = last_payment_amount;
          data.is_monthly_refund = true;
        } else {
          data.end_type = subscriptionEndType;
        }

        handleCancellation(data);
        toggle();
        return;
      }

      // Original flow for other scenarios
      const data = {
        order_status: cancelledBy === "planeOwner" ? STATUS.ORDER_CANCELED : STATUS.ORDER_REJECTED,
        cancellation_reason: cancellationReason
      };

      if (isRefundMode) {
        data.refund_type = refundType;

        if (refundType === "full") {
          data.refund_amount = fullRefundAmount;
          data.apply_stripe_fee = false;
          data.apply_service_fee = false;
        } else if (refundType === "partial") {
          if (selectedPartialOption && selectedPartialOption !== "custom") {
            const options = getPartialRefundOptions();
            const option = options[selectedPartialOption];
            data.refund_amount = option.value;
            data.apply_stripe_fee = option.applyStripeFee;
            data.apply_service_fee = option.applyServiceFee;
          } else {
            data.refund_amount = parseFloat(refundAmount);
            data.apply_stripe_fee = false;
            data.apply_service_fee = false;
          }
        }
      }

      handleCancellation(data);
      toggle();
    }
  };

  const resetForm = () => {
    setCancelledBy("");
    setCancellationReason("");
    setRefundType("");
    setRefundAmount("");
    setSelectedPartialOption("");
    setSubscriptionEndType("");
    setErrors({});
  };

  const handleClose = () => {
    resetForm();
    toggle();
  };

  // Error clearing handlers
  const handleRadioChange = (value) => {
    setCancelledBy(value);
    if (errors.cancelledBy) {
      setErrors(prev => ({ ...prev, cancelledBy: "" }));
    }
  };

  const handleReasonChange = (e) => {
    const value = e.target.value;
    setCancellationReason(value);
    if (value.trim() && errors.cancellationReason) {
      setErrors(prev => ({ ...prev, cancellationReason: "" }));
    }
  };

  const handleRefundTypeChange = (value) => {
    setRefundType(value);
    setSelectedPartialOption("");
    setRefundAmount("");
    if (errors.refundType) {
      setErrors(prev => ({ ...prev, refundType: "" }));
    }
  };

  // function to toggle monthly refund option
  const toggleMonthlyRefund = () => {
    setIsMonthlyRefund(!isMonthlyRefund);
    if (!isMonthlyRefund) {
      setRefundType("last_payment");
    } else {
      setRefundType("");
    }
  };

  const handleSubscriptionEndTypeChange = (value) => {
    setSubscriptionEndType(value);
    if (errors.subscriptionEndType) {
      setErrors(prev => ({ ...prev, subscriptionEndType: "" }));
    }
  };

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      const maxRefund = totalAmount - stripeFee;

      setRefundAmount(value);
      setSelectedPartialOption("");

      if (value !== '' && parseFloat(value) > maxRefund) {
        setErrors(prev => ({
          ...prev,
          refundAmount: `Refund amount cannot exceed ${formatCurrency(maxRefund)}`
        }));
      } else if (parseFloat(value) > 0 && errors.refundAmount) {
        setErrors(prev => ({ ...prev, refundAmount: "" }));
      }
    }
  };

  const formatCurrency = (amount) => {
    return parseFloat(amount).toFixed(2);
  };

  const partialRefundOptions = getPartialRefundOptions();
  const currentDate = moment().format(DATE_FORMATS.LONG);

  const getModalTitle = () => {
    if (isCanceledStatus && isRefundMode) {
      return "Refund Reservation";
    }
    if (rental_type_monthly && !isPendingStatus) {
      return isMonthlyRefund ? "End & Refund Reservation" : "End Reservation";
    }
    return isRefundMode ? "Cancel and Refund Reservation" : "Cancel Reservation";
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>
        {getModalTitle()}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center py-4">
            <Spinner color="primary" />
            <p className="mt-2">Checking payment status...</p>
          </div>
        ) : (
          <>
            {/* Who requested section - only for non-monthly or pending monthly cases AND not for canceled status */}
            {!(rental_type_monthly && !isPendingStatus) && !isCanceledStatus && (
              <div className="mb-3">
                <div className="fw-bold mb-2">Who requested the cancellation? *</div>

                <div className="mb-2">
                  <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                    <input
                      type="radio"
                      style={{ marginRight: "8px" }}
                      className="cursor-pointer"
                      checked={cancelledBy === "planeOwner"}
                      onClick={() => handleRadioChange("planeOwner")}
                    />
                    Plane Owner requested cancellation
                  </label>
                </div>

                <div className="mb-2">
                  <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                    <input
                      type="radio"
                      style={{ marginRight: "8px" }}
                      className="cursor-pointer"
                      checked={cancelledBy === "hangarOwner"}
                      onClick={() => handleRadioChange("hangarOwner")}
                    />
                    Hangar Owner requested cancellation
                  </label>
                </div>

                {errors.cancelledBy && (
                  <div className="text-danger small mt-1">{errors.cancelledBy}</div>
                )}
              </div>
            )}

            {/* case for monthly rental that's not in pending status */}
            {rental_type_monthly && !isPendingStatus && !isCanceledStatus && !isMonthlyRefund && (
              <div className="mb-3">
                <div className="fw-bold mb-2">When would you like to end this subscription? *</div>

                <div className="mb-2">
                  <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                    <input
                      type="radio"
                      style={{ marginRight: "8px" }}
                      className="cursor-pointer"
                      checked={subscriptionEndType === "immediate"}
                      onClick={() => handleSubscriptionEndTypeChange("immediate")}
                    />
                    End Immediately
                    <span className="ms-2 text-muted">({currentDate})</span>
                  </label>
                </div>

                {!cancel_at_end && !isMonthlyRefund && <div className="mb-2">
                  <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                    <input
                      type="radio"
                      style={{ marginRight: "8px" }}
                      className="cursor-pointer"
                      checked={subscriptionEndType === "period_end"}
                      onClick={() => handleSubscriptionEndTypeChange("period_end")}
                    />
                    End at Period End
                    <span className="ms-2 text-muted">({check_out})</span>
                  </label>
                </div>}

                {errors.subscriptionEndType && (
                  <div className="text-danger small mt-1">{errors.subscriptionEndType}</div>
                )}
              </div>
            )}

            {/* Refund mode section - used for refund-only (canceled) and regular cancel & refund */}
            {isRefundMode && (
              <div className="mb-3">
                <div className="fw-bold mb-2">Refund Type *</div>

                <div className="mb-2">
                  <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                    <input
                      type="radio"
                      style={{ marginRight: "8px" }}
                      checked={refundType === "full"}
                      className="cursor-pointer"
                      onClick={() => handleRefundTypeChange("full")}
                    />
                    <span>
                      Full Refund (${formatCurrency(totalAmount)})
                    </span>
                  </label>
                </div>

                <div className="mb-2">
                  <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                    <input
                      type="radio"
                      style={{ marginRight: "8px" }}
                      checked={refundType === "partial"}
                      onClick={() => handleRefundTypeChange("partial")}
                      className="cursor-pointer"
                    />
                    <span>
                      Partial Refund {!!stripeFee && `(Stripe Fee: ${formatCurrency(stripeFee)})`}
                    </span>
                  </label>
                </div>

                {refundType === "partial" && (
                  <div className="mt-2 mb-2 border p-3 rounded bg-light">
                    <div className="fw-bold mb-1">Refund Options</div>
                    {applyStripeFee && stripeFee > 0 && (
                      <small className="text-muted d-block mb-2">
                        Note: Stripe fee calculation (2.9% + $0.30)
                      </small>
                    )}

                    {/* Option 1: Refund excluding Service Fee (and Stripe Fee if applicable) */}
                    <div className="mb-2">
                      <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                        <input
                          type="radio"
                          style={{ marginRight: "8px" }}
                          className="cursor-pointer"
                          checked={selectedPartialOption === "minusBothFees"}
                          onClick={() => handlePartialOptionSelect("minusBothFees")}
                        />
                        <span>
                          {stripeFee > 0
                            ? "Refund excluding Service Fee and Stripe Fee"
                            : "Refund excluding Service Fee"}
                          (${formatCurrency(partialRefundOptions.minusBothFees.value)})
                        </span>
                      </label>
                    </div>

                    {/* Option 2: Refund minus Stripe Fee - Only show if stripeFee exists */}
                    {stripeFee > 0 && (
                      <div className="mb-2">
                        <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                          <input
                            type="radio"
                            style={{ marginRight: "8px" }}
                            checked={selectedPartialOption === "minusStripeFee"}
                            className="cursor-pointer"
                            onClick={() => handlePartialOptionSelect("minusStripeFee")}
                          />
                          <span>
                            {partialRefundOptions.minusStripeFee.label} (${formatCurrency(partialRefundOptions.minusStripeFee.value)})
                          </span>
                        </label>
                      </div>
                    )}

                    {/* Option 4: Custom Amount - Always show */}
                    <div className="mb-2">
                      <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                        <input
                          type="radio"
                          style={{ marginRight: "8px" }}
                          className="cursor-pointer"
                          checked={selectedPartialOption === "custom" || (!selectedPartialOption && refundAmount)}
                          onClick={() => handlePartialOptionSelect("custom")}
                        />
                        {partialRefundOptions.custom.label}
                      </label>
                    </div>

                    <div className="mt-3">
                      <InputGroup>
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Input
                          type="text"
                          placeholder="Enter refund amount"
                          value={refundAmount}
                          onChange={handleRefundAmountChange}
                        />
                      </InputGroup>

                      <small className="text-muted d-block mt-1">
                        Maximum refund: ${formatCurrency(totalAmount - stripeFee)}
                      </small>

                      {errors.refundAmount && (
                        <div className="text-danger small mt-1">{errors.refundAmount}</div>
                      )}
                    </div>
                  </div>
                )}

                {errors.refundType && (
                  <div className="text-danger small mt-1">{errors.refundType}</div>
                )}
              </div>
            )}

            {/* Monthly refund option section */}
            {rental_type_monthly && !isPendingStatus && canEndAndRefundMonthly && isMonthlyRefund && !isCanceledStatus && (
              <div className={`mb-2 mt-2 border p-3 rounded bg-light`}>
                <div className="fw-bold mb-2">Refund</div>
                <div className="text-muted mb-2">
                  <small>Note: The subscription will end immediately upon refund.</small>
                </div>

                {/* Monthly refund option */}
                <div className="mt-2 border-top ">
                  <div className="mb-2">
                    <label className="d-flex align-items-center cursor-pointer" style={{ width: "fit-content" }}>
                      <input
                        type="radio"
                        style={{ marginRight: "8px" }}
                        className="cursor-pointer"
                        checked={refundType === "last_payment"}
                        onClick={() => handleRefundTypeChange("last_payment")}
                      />
                      <span>
                        Last payment (${formatCurrency(last_payment_amount)})
                      </span>
                    </label>
                  </div>

                  {errors.refundType && (
                    <div className="text-danger small mt-1">{errors.refundType}</div>
                  )}
                </div>
              </div>
            )}

            {/* Show cancellation reason only for non-monthly or pending monthly cases and not for canceled status */}
            {!(rental_type_monthly && !isPendingStatus) && !isCanceledStatus && (
              <div className="mb-3">
                <div className="fw-bold mb-2">Cancellation Reason *</div>
                <textarea
                  className="form-control"
                  rows="4"
                  value={cancellationReason}
                  onChange={handleReasonChange}
                />
                {errors.cancellationReason && (
                  <div className="text-danger small mt-1">{errors.cancellationReason}</div>
                )}
              </div>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color={isCanceledStatus ? "danger" : (isMonthlyRefund ? "primary" : "danger")}
          onClick={handleSubmit}
          disabled={loading}
        >
          {isCanceledStatus && isRefundMode
            ? "Process Refund"
            : (rental_type_monthly && !isPendingStatus
              ? (isMonthlyRefund ? "End & Refund Reservation" : "End Reservation")
              : (isRefundMode ? "Cancel & Refund" : "Confirm Cancellation"))}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancellationRefundModal;