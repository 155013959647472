import DefaultLoading from "common/loading"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import { TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import { Card, CardBody, Container } from "reactstrap"
import Swal from "sweetalert2"


const PageMetaDashboard = () => {

  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [pageMetaData, setPageMetaData] = useState([]);


  const fetchData = async () => {
    setLoading(true);
    try {
      let { data, error } = await supabase
        .from(TABLES.PAGE_META)
        .select("*")
        .order("id", { ascending: true });

      if (error) throw error;

      setPageMetaData(data);
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false);
  };


  const deleteConfirmation = (row) => {
    Swal.fire({
      title: "Do you want to delete this Page Meta?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`
    }).then((result) => {
      if (result.isDenied) {
        handleDelete(row)
      }
      Swal.close()
    });
  }

  const handleDelete = async (row) => {
    try {
      const { error } = await supabase
        .from(TABLES.PAGE_META)
        .delete()
        .eq('id', row.id);

      if (error) {
        throw error;
      }
      toast.success("Meta deleted successfully")
      fetchData();
    } catch (error) {
      console.error('Error deleting record:', error.message);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  document.title = "Help desk | HangarDirect"

  const columns = useMemo(
    () => [

      {
        Header: "Id",
        filterable: false,
        disableFilters: true,
        accessor: row => <Link to="#" className="text-body fw-bold">
          {row.id}
        </Link>
        ,
      },
      {
        Header: "Slug",
        accessor: "slug",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Seo Title",
        accessor: "seo_title",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Seo Description",
        accessor: "seo_description",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Created date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return moment(cellProps.row.original.created_at).format("LL")
        },
      },

      {
        Header: "Action",
        disableFilters: true,
        accessor: row => {
          return (
            <div className="d-flex gap-2 align-items-center" style={{ width: "100px" }}>

              <img onClick={() => {
                history.push("/edit-page-meta?id=" + row.id)
              }} className="cursor-pointer" style={{ height: "25px" }} src="/images/editart.png" alt="" />
              <img onClick={() => deleteConfirmation(row)} className="cursor-pointer" style={{ height: "25px" }} src="/images/delart.png" alt="" />
            </div>
          )
        },

      },
    ],
    []
  )


  return (

    <Container fluid className="page-content">
      {loading ? (
        <DefaultLoading />
      ) : (
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="card-title">Page Meta</div>

              <Link to="/create-page-meta">
                <button className="btn btn-info btn-sm px-3 rounded py-2 text-lg mb-2" style={{ fontSize: "16px" }}>
                  + Page Meta
                </button>
              </Link>

            </div>

            <TableContainer
              columns={columns}
              data={pageMetaData}
              isGlobalFilter={false}
              isAddOptions={false}
              currentItems={pageMetaData}
            />
          </CardBody>
        </Card>
      )}
    </Container>
  )
}

export default PageMetaDashboard

