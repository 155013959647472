import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import {
  OrderId,
  Title,
  Date,
  UserName,
  StripeId,
  Status,
  Amount,
  Action,
  Email,
} from "./LatestPayoutsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading, { Spinner } from "common/loading"
import Payouts from "./payouts"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import { fundsTransfer } from "pages/Utility/stripe_functions"

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY

const stripe = require("stripe")(KEY)

const PayoutPage = () => {
  document.title = "Payouts | HangarDirect"
  const [loading, setLoading] = useState(false)
  const [payoutsData, setPayoutsData] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [rowLoading, setRowLoading] = useState(-1);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    getPayoutsData()
  }, [])

  const getPayoutsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.PAYOUTS)
        .select("*,user_id(*)")
        .order("id", { ascending: true })
        .eq("status", STATUS?.PAYOUT_PENDING)
      if (error) {
        throw error
      }
      setPayoutsData(data);
      setCurrentItems(data);
    } catch (error) { }
    setLoading(false)
  }

  const getAccountData = async row => {
    try {
      setRowLoading(row.id);
      const { user_stripe_account } = row;

      const account = await stripe.accounts.retrieve(user_stripe_account);

      setModelPopData(account);
      setRowLoading(-1);
    } catch (error) {
      console.log('error: ', error.message);
      toast.dismiss();
      toast.error(error.message);
      setRowLoading(-1);
    }
  };

  // Handle payout approval
  const handlePayout = async (rowData) => {
    const {
      user_stripe_account: stripeAcc,
      amount: payAmount,
      id,
      transfer_id, 
      user_id, 
      stripe_payout_method_id 
    } = rowData;

    try {
      const result = await Swal.fire({
        title: 'Are you sure you want to approve this payout?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve!'
      });

      if (!result.isConfirmed) {
        return;
      }

      Swal.fire({
        title: 'Processing...',
        didOpen: () => Swal.showLoading(),
        allowOutsideClick: false
      });

      // Checking connect account approval
      setLoading(true);
      const { data: profile, error } = await supabase
        .from(TABLES.USER_PROFILE)
        .select('connect_account_approval')
        .eq('id', user_id.id)
        .single();

      if (error) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        });
      }

      if (!profile?.connect_account_approval) {
        setLoading(false);
        return Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: "Connect account does not approve yet from Stripe Side!"
        });
      }

      // Process the payout
      let amount = Math.round(+payAmount * 100);

      const response = await fundsTransfer({ 
        stripeAcc, 
        amount, 
        transfer_id, 
        stripePayoutMethodId: stripe_payout_method_id 
      });

      if (response?.payoutID && response?.transferID) {
        const { data: update, error } = await supabase
          .from(TABLES.PAYOUTS)
          .update({
            transfer_id: response?.transferID,
            payout_id: response?.payoutID,
            status: STATUS.PAYOUT_COMPLETED,
          })
          .eq("id", id);

        if (error) {
          setLoading(false);
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Supabase not updating"
          });
        }

        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Payout processed successfully.',
        });

        getPayoutsData();
      } else {
        const { data, error: err } = await supabase
          .from(TABLES.PAYOUTS)
          .update({ transfer_id: response?.transferID })
          .eq("id", id);

        if (err) {
          setLoading(false);
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.message
          });
        }
        getPayoutsData();
      }
    } catch (error) {
      console.error("Error in handlePayout:", error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Something went wrong!'
      });
    }
  };

  // Handle payout rejection
  const handleReject = async (rowData) => {
    const { id } = rowData;
    
    try {
      const result = await Swal.fire({
        title: 'Are you sure you want to reject this payout?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject!'
      });

      if (!result.isConfirmed) {
        return;
      }

      setLoading(true);
      const { data, error } = await supabase
        .from(TABLES.PAYOUTS)
        .update({ status: STATUS.PAYOUT_REJECTED })
        .eq("id", id);
        
      if (error) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        });
      }
      
      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Payout has been rejected successfully.',
        timer: 2000
      });
      
      getPayoutsData();
    } catch (error) {
      console.error("Error in handleReject:", error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Something went wrong!'
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "user_id.first_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <UserName {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "user_id.email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Stripe Acc",
        accessor: "Stripe Acc",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StripeId {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        Cell: cellProps => {
          const rowData = cellProps.row.original;
          return (
            <div className="d-flex">
              <Button
                type="button"
                color="primary"
                className="btn-sm me-2"
                onClick={() => handlePayout(rowData)}
              >
                Approve
              </Button>
              <Button
                type="button"
                color="danger"
                className="btn-sm"
                onClick={() => handleReject(rowData)}
              >
                Reject
              </Button>
            </div>
          );
        },
      },
      {
        Header: "View Account",
        disableFilters: true,
        accessor: row => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                getAccountData(row)
              }}
            >
              {rowLoading === row.id ? <Spinner wT={70} hT={10} r={10} /> : "View Details"}
            </Button>
          )
        },
      },
    ],
    [rowLoading]
  )

  return (
    <Container className="page-content" fluid>
      {loading ? (
        <DefaultLoading />
      ) : (
        <Row>
          <Col lg="12">
            <Payouts
              columns={columns}
              payoutsData={payoutsData}
              modelPopData={modelPopData}
              closeModal={() => setModelPopData(null)}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default PayoutPage