import { supabase } from "pages/Utility/supabase"
import React from "react"
import { Col, Container, Row } from "reactstrap"
import { DATE_FORMATS, STATUS, TABLES } from "pages/Utility/constants"
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import { useState, useRef, useEffect } from "react"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import moment from "moment"
import CustomerDetail from "../../components/Review/CustomerDetail"
import { toast } from "react-toastify"
import { getImageUrl } from "pages/Utility/functions"

const ReviewDetails = props => {
  document.title = "Reviews details - hangarDirect"
  const history = useHistory()
  const router = useRouteMatch()
  const firstNameRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [approval, setApproval] = useState(true)
  const [profileData, setprofileData] = useState([])
  const [amenity, setAmenity] = useState([])
  const [rules, setRules] = useState([])
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }

  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const [hangarData, setHangarData] = useState(null)
  const [transactionData, setTransactionData] = useState(null)
  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params && params.id) {
      fetchData(params.id)
      // getOrderData(params.id)
    }
  }, [history])
  const fetchData = async id => {
    try {
      const { data, error } = await supabase
        .from(TABLES.RESERVATIONS)
        .select("*,review(*)")
        .eq("id", id)
        .single()
      if (error) throw error
      if (data) {

        setTransactionData(data)
        getOrderData(data?.hangar_id)
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }
  useEffect(() => {
    if (params && params.id) {
      supabase
        .from(TABLES.HANGAR_AMENITIES)
        .select("*, amenity(*)")
        .eq("hangar_id", params.id)
        .then(({ data = [], error }) => {
          if (data) {
            setAmenity(data.map(i => i.amenity))
          }
          if (error) toast.error(error.message)
        })
    }
  }, [hangarData, history])
  useEffect(() => {
    if (!hangarData?.user_id) return
    check_coonect_account_approval()
  }, [hangarData?.user_id])
  const check_coonect_account_approval = async () => {
    setLoading(true)
    const { data, error } = await supabase
      .from(TABLES.USER_PROFILE)
      .select("*")
      .eq("id", hangarData.user_id)
      .single()
    if (error) {
      setLoading(false)
    }
    if (data) {
      setLoading(false)
      setApproval(data?.connect_account_approval)
      setprofileData(data)
      if (!data?.connect_account_approval) {
        setAlert({
          heading: "Restricted",
          message: "This user does not have connect account.",
          type: "danger",
        })
      }
    } else {
      setLoading(false)
      return false
    }
  }

  const getOrderData = async id => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
        .from(TABLES.HANGARS)
        .select("*,profile(*),hangar_rule(*,rules(*)),property_rules(*)")
        .eq("id", id)
        .single()

      if (error) {
        throw error
      } else if (data) {
        setHangarData(data)
        setRules(data?.hangar_rule)
      }
    } catch (error) {
      toast.error(error.message)
      console.log("error: ", error.message)
    }
    setLoading(false)
  }
  const statusHandler = async value => {
    setLoading(true)
    try {
      const { data: update, error } = await supabase
        .from(TABLES.HANGARS)
        .update({ status: value })
        .eq("id", params.id)
        .select()
      if (error) {
        setLoading(false)
        throw error
      } else {
        history.push("/hangar")
      }

      setLoading(false)
    } catch (error) { }
  }
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`} style={{ marginBottom: 0 }}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <>
              {!approval && (
                <Alerts
                  type={alert.type}
                  heading={alert.heading}
                  message={alert.message}
                  clear={() => setAlert(DEFAULT_ALERT_VALUES)}
                />
              )}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Heading text="Review Details" customClass="px-0" />
                <button
                  onClick={() => history.goBack()}
                  style={{
                    backgroundColor: "#74788D",
                    color: "white",
                    marginLeft: "8px",
                  }}
                  className="btn"
                >
                  Go back
                </button>
              </div>

              <Row>
                <Col xl="8">
                  <div className="bg-white px-2 rounded">
                    <div className="container pt-3">
                      <Heading text="Hangar Info" customClass="m-0 px-0 pt-0" />
                      <div className="border-top border-bottom py-2">
                        <div className="row px-2">
                          {/* ist row */}
                          <div
                            style={{ fontWeight: "600" }}
                            className="col-lg-2"
                          >
                            Title:
                          </div>
                          <div className="col-lg-5">{hangarData?.title}</div>
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Per Night Price:
                          </div>
                          <div className="col-sm">
                            ${hangarData?.price_per_night?.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 2nd row */}
                    <div className="container py-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Per Month Price:
                          </div>
                          <div className="col-lg-5">
                            ${hangarData?.price_per_month?.toFixed(2)}
                          </div>
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Width:
                          </div>
                          <div className="col-sm">
                            {hangarData?.width_feet +
                              "'" +
                              hangarData?.width_inch +
                              "''"}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 3rd row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <div
                            style={{ fontWeight: "600" }}
                            className="col-lg-2"
                          >
                            Length:
                          </div>
                          <div className="col-lg-5">
                            {hangarData?.length_feet +
                              "'" +
                              hangarData?.length_inch +
                              "''"}
                          </div>
                          {/* <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Height:
                          </div>
                          <div className="col-sm">
                            {hangarData?.height_feet +
                              "'" +
                              hangarData?.height_inch +
                              "''"}
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* 4rth row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Door Height:
                          </div>
                          <div className="col-lg-5">
                            {hangarData?.door_height_feet +
                              "'" +
                              hangarData?.door_height_inch +
                              "''"}
                          </div>
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Door Width:
                          </div>
                          <div className="col-sm">
                            {hangarData?.door_width_feet +
                              "'" +
                              hangarData?.door_width_inch +
                              "''"}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 5th row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Description:
                          </div>
                          <div
                            className="col-lg-10"
                            dangerouslySetInnerHTML={{
                              __html: hangarData.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="bg-white px-3 mt-4 rounded py-3">
                    <div
                      style={{ fontSize: "13px", fontWeight: "600" }}
                      className="border-bottom py-2"
                    >
                      Hangar Owner Detail
                    </div>
                    <div className="d-flex gap-4 mt-2">
                      <div className="d-flex gap-2">
                        <img
                          style={{ height: "15px" }}
                          src="/images/profile.png"
                          alt=""
                        />
                        <span>
                          {hangarData?.profile?.meta?.first_name +
                            " " +
                            hangarData?.profile?.meta?.last_name}
                        </span>
                      </div>
                      <div className="d-flex gap-2">
                        <img
                          style={{ height: "14px" }}
                          src="/images/msg.png"
                          alt=""
                        />
                        <span>{hangarData?.profile?.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-2 rounded mt-4 ">
                    <div className="container pt-3">
                      <Heading
                        text="Order Detail"
                        customClass="m-0 px-0 pt-0"
                      />
                      <div className="border-top border-bottom py-2">
                        <div className="row px-2">
                          {/* ist row */}
                          <div
                            style={{ fontWeight: "600" }}
                            className="col-lg-2"
                          >
                            Order#:
                          </div>
                          <div className="col-lg-5">{transactionData?.id}</div>
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Order date:
                          </div>
                          <div className="col-sm">
                            {moment(transactionData?.created_at).format(
                              "DD MMM YYYY"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 2nd row */}
                    <div className="container py-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Reserved for:
                          </div>
                          <div className="col-lg-5">
                            {+transactionData?.days === 1 ? transactionData?.days + " day" : transactionData?.days + " days"}
                          </div>
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Total:
                          </div>
                          <div className="col-sm">
                            $ {transactionData?.total}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 3rd row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <div
                            style={{ fontWeight: "600" }}
                            className="col-lg-2"
                          >
                            Check in:
                          </div>
                          <div className="col-lg-5">
                            {moment(transactionData?.check_in).format("DD MMM YYYY")}
                          </div>
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Payment:
                          </div>
                          <div className="col-sm">Paid</div>
                        </div>
                      </div>
                    </div>
                    {/* 4rth row */}
                    <div className="container pb-3 ">
                      <div className=" pb-2">
                        <div className="row px-2">
                          <div
                            className="col-lg-2"
                            style={{ fontWeight: "600" }}
                          >
                            Check out:
                          </div>
                          <div className="col-lg-5">
                            {moment(transactionData?.check_out).format("DD MMM YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white px-2 mt-4 rounded pb-2">
                    <div className="container">
                      <Heading text="Hangar Images" customClass="m-0 px-0" />
                      <div className="d-flex flex-wrap gap-5">
                        {hangarData?.images?.map(item => (
                          <div className="d-flex pt-4" key={item}>
                            <img
                              height={"170px"}
                              className="rounded"
                              width={"170px"}
                              src={getImageUrl(item)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl="4" className="p-0">

                  <div
                    className={` ${hangarData?.status == 1 ? "mt-3 mx-2" : "mx-2"
                      } pb-2 bg-white rounded`}
                  >
                    {/* <Heading text="Hangar Owner Detail" customClass="m-0 " /> */}

                    <div className="px-3 py-2">
                      <div className="d-flex align-items-center justify-content-between gap-2">
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          Review ID:
                        </span>
                        <span style={{ fontSize: "15px" }}>
                          {transactionData?.review?.id}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-2 pt-2">
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          Date posted:
                        </span>
                        <span style={{ fontSize: "15px" }}>
                          {moment(transactionData?.review?.created_at).format(DATE_FORMATS.CREATED_AT)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={` mx-2 pb-2 mt-3 bg-white rounded`}>
                    <Heading text="Rating" customClass="m-0" />

                    <div className="px-3 py-2">
                      <div className="d-flex gap-2">
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>
                          {transactionData?.review?.rating}
                        </span>
                        <span>
                          {Array.from(
                            { length: transactionData?.review?.rating },
                            (_, index) => index + 1
                          )?.map(item => (
                            <img key={item} src="/images/Star 1.png" alt="" />
                          ))}
                        </span>
                      </div>
                      <div className=" pt-2">
                        <div style={{ fontSize: "15px", fontWeight: "500" }}>
                          Detail
                        </div>
                        <div style={{ fontSize: "15px", paddingTop: "4px" }}>
                          {transactionData?.review?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={` mx-2 pb-2 mt-3 bg-white rounded`}>
                    <Heading text="Customer Detail" customClass="m-0" />
                    <CustomerDetail
                      {...{
                        profileData,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ReviewDetails
