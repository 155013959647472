import Alerts from "common/Alerts/Alert"
import DefaultLoading from "common/loading"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import { DATE_FORMATS, STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { Button, Card, Container, } from "reactstrap"
import Swal from "sweetalert2"
import AddAdvertisementModal from "./AdvertisementAddModal.tsx"

const DEFAULT_ALERT_VALUES = {
  heading: "",
  message: "",
  type: "",
}

const AdvertisementListPage = () => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Advertisement List | HangarDirect"


  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoading(true)
    try {

      const { data, error } = await supabase.from(TABLES.ADVERTISEMENT).select("*")

      if (error) {
        throw error
      }
      setData(data)
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false)
  }

  const changeInterval = async (e) => {

    const interval = +e.target.value;
    if (!interval) {
      return e.target.blur()
    }

    try {

      const { data, error } = await supabase
        .from(TABLES.ADVERTISEMENT)
        .update({ interval })
        .not("id", "eq", 0)

      if (error) throw error
      else {
        toast.success("Interval changed successfully to " + interval + " minutes")
        getData()
      }

    } catch (error) {

      toast.error(error.message);
    }
  }

  const deletePopup = async id => {
    Swal.fire({
      title: "Do you want to delete this Advertisement?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then(result => {
      if (result.isDenied) {
        deleteRow(id)
      }
      Swal.close()
    })
  }

  const deleteRow = async id => {

    try {


      setLoading(true)
      const { data, error } = await supabase
        .from(TABLES.ADVERTISEMENT)
        .delete()
        .eq("id", id)
      if (error) throw error
      else {
        getData()
      }

    } catch (error) {

      setLoading(false)
      toast.error(error.message);
    }

  }

  const Header = useMemo(() => (
    <div className="d-flex align-items-center justify-content-end ">

      <svg
        width="20px"
        height="20px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer', marginRight: 10 }}
        onClick={() => {
          getData()
        }}
      >
        <path
          d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 5.26904 1.54367 3.28183 3.1694 2M7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 9.73096 13.4563 11.7182 11.8306 13M11.5 10V13.5H15M0 1.5H3.5V5"
          stroke="#000000"
        />
      </svg>

      <div className="rounded mx-2"  >
        {"Interval:"}
        <input
          defaultValue={data[0]?.interval}
          style={{ minWidth: "10px", width: "auto", border: "none", height: "29px" }}
          type="number"
          className="rounded mx-1 text-success border-success focus-ring"
          onKeyDown={e => {
            e.key === "Enter" && changeInterval(e)
          }}
          onBlur={e => {
            e.target.value = data[0]?.interval
          }}
        />
      </div>


      <Button
        type="button"
        color="info"
        className="rounded"
        onClick={() => {
          setAddModalVisible(true)
        }}
      >
        + Add New
      </Button>
    </div >
  ), [data])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Link",
        accessor: "link",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Images",
        accessor: ({ images }) => <div className="d-flex" >
          <img src={images.mobile} style={{ width: "30px", height: "30px", borderRadius: 2 }} />
          <img src={images.tablet} style={{ width: "30px", height: "30px", borderRadius: 2 }} />
          <img src={images.laptop} style={{ width: "30px", height: "30px", borderRadius: 2 }} />
          <img src={images.xxl} style={{ width: "30px", height: "30px", borderRadius: 2 }} />
        </div>
        ,
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Status",
        filterable: true,
        disableFilters: true,
        accessor: row => {
          return (

            <span
              className={`${row.status == STATUS.ADVERTISEMENT_ACTIVE ? "bg-success" : "bg-danger"
                } bg-opacity-25 px-1 rounded-1`}
              style={{ color: row.status == STATUS.ADVERTISEMENT_ACTIVE ? "#34C38F" : "#F46A6A" }}
            >
              {row.status == STATUS.ADVERTISEMENT_ACTIVE ? "Active" : "Inactive"}
            </span>
          )
        },
      },
      {
        Header: "Created Date",
        filterable: true,
        disableFilters: true,

        accessor: ({ created_at }) => {
          return moment(created_at).format(DATE_FORMATS.CREATED_AT)
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return (
            <div className="d-flex gap-1 cursor-pointer">
              <img
                onClick={() => {
                  setSelectedRow(row);
                  setAddModalVisible(true)
                }}
                style={{ height: "30px" }}
                src="/images/edit.png"
                alt=""
              />
              <img
                onClick={() => deletePopup(row.id)}
                style={{ height: "30px" }}
                src="/images/delete.png"
                alt=""
              />
            </div>
          )
        },
      },
    ],
    []
  )


  return (
    <div className="page-content">


      <Container fluid>
        {alert.type && (
          <Alerts
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            clear={() => setAlert(DEFAULT_ALERT_VALUES)}
          />
        )}
        <div className="d-flex justify-content-between">
          <div className=" h4 card-title">Advertisement List</div>
        </div>

        <Card className="mb-2">

          <div className="px-3 my-3" >

            {
              loading &&
              <DefaultLoading style={{ position: "absolute", width: "100%", height: "100%" }} />
            }

            <TableContainer
              columns={columns}
              header={Header}
              data={data}
              isGlobalFilter={false}
              isAddOptions={false}
              currentItems={data}
            />
          </div>

        </Card>

      </Container>


      <AddAdvertisementModal visible={addModalVisible} data={selectedRow} interval={data[0]?.interval || 120} toggle={(refresh?: boolean) => {
        setAddModalVisible(false)
        setSelectedRow(null)
        refresh === true && getData()
      }} />


    </div>
  )
}

export default AdvertisementListPage