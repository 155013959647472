import moment from "moment"
import { DATE_FORMATS } from "pages/Utility/constants"
import React, { memo } from "react"
import { getOrderStatus } from "utils/orderStatusHelper"
import InfoBadge from "./InfoIcon"

// Helper components
const Heading = ({ text, customClass }) => (
  <nav aria-label="breadcrumb">
    <ol className={`breadcrumb ${customClass}`}>
      <li
        style={{ fontWeight: "600", color: "#495057" }}
        className="breadcrumb-item active"
        aria-current="page"
      >
        {text}
      </li>
    </ol>
  </nav>
)

const DetailRow = ({ label, value, customStyle = {}, valueClass = "" }) => (
  <div className="container pb-3">
    <div className="border-bottom pb-2">
      <div className="d-flex justify-content-between px-2">
        <div style={{ fontWeight: "600" }}>{label}</div>
        <div style={{ fontWeight: "500", ...customStyle }} className={valueClass}>
          {value}
        </div>
      </div>
    </div>
  </div>
)

const StatusDisplay = ({ params }) => {
  const statusData = getOrderStatus(params);

  return (
    <InfoBadge
      className={`${statusData.bgColor} py-1 px-2  ${statusData.textColor}`}
      title={statusData.text}
      description={statusData.description || "Status information not available"}
      tooltipWidth="250px"
      tooltipTop="-88px"
      tooltipRight="8px"
      tooltipZIndex={1000}
      tooltipShadow="0px 1px 4px 0px rgba(0,0,0,0.25)"
    />
  );
};

const PaymentStatusBadge = ({ status }) => {
  let bgColorClass = "bg-success";
  let textColor = "#24902F";
  let displayText = status;

  if (status === "succeeded") {
    displayText = "Paid";
    bgColorClass = "bg-success";
    textColor = "#24902F";
  } else if (status === "refunded") {
    bgColorClass = "bg-warning";
    textColor = "#856404";
  } else if (status === "partial_refunded") {
    displayText = "Partially refunded";
    bgColorClass = "bg-info";
    textColor = "#6f42c1";
  }

  return (
    <div
      className={`${bgColorClass} text-capitalize bg-opacity-10 px-2 py-0.5 rounded-2`}
      style={{
        color: textColor,
        fontWeight: "500"
      }}
    >
      {displayText}
    </div>
  );
};

// Section components
const ReservationDetailsSection = ({ bookingInfo, statusInfo, aircraftInfo, ownerInfo, cancel_at_end }) => {
  const { check_in, check_out, rental_type, rental_type_daily, day } = bookingInfo || {}
  const { order_status } = statusInfo || {}
  const { aircraft_name, tail_number } = aircraftInfo || {}
  const { params } = ownerInfo || {}

  return (
    <div className="bg-white px-1 rounded mt-4 w-100">
      <div className="container pt-3">
        <Heading text="Reservation Details" customClass="m-0 px-0 pt-0 pb-4" />

        <DetailRow
          label="Created at"
          value={bookingInfo.created_at || 'N/A'}
        />
        <DetailRow
          label="Check in"
          value={check_in || 'N/A'}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Check out"
          value={check_out || 'N/A'}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Ending on check out"
          value={rental_type_daily || cancel_at_end ? "True" : "False"}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Reserved for"
          value={rental_type_daily ? ` ${day} ${day > 1 ? 'days' : 'day'}` : "Monthly" || 'N/A'}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Rental type"
          value={rental_type || 'N/A'}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Status"
          value={order_status ? <StatusDisplay params={params} /> : 'N/A'}
        />
        <DetailRow
          label="Aircraft Name"
          value={aircraft_name || "N/A"}
        />
        <DetailRow
          label="Tail Number"
          value={tail_number || "N/A"}
        />
      </div>
    </div>
  )
}

const PaymentDetailsSection = ({ paymentInfo, premium_amenities_amount }) => {
  const { payment_intent, tax, serviceFee, credit, total, reservation_amount } = paymentInfo || {}

  return (
    <div className="bg-white px-1 rounded mt-4 w-100">
      <div className="container pt-3">
        <Heading text="Payment details" customClass="m-0 px-0 pt-0 pb-4" />

        <DetailRow
          label="Payment method"
          value={payment_intent?.payment_method_card_brand || 'N/A'}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Payment method type"
          value={payment_intent?.payment_method_type || 'N/A'}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Payment status"
          value={<PaymentStatusBadge status={payment_intent?.payment_status || 'N/A'} />}
          valueClass="text-capitalize"
        />
        <DetailRow
          label="Reservation amount"
          value={`$${reservation_amount?.toFixed(2) || '0.00'}`}
        />
        <DetailRow
          label="Tax"
          value={`$${tax?.toFixed(2) || '0.00'}`}
        />
        <DetailRow
          label="Service fee"
          value={`$${serviceFee?.toFixed(2) || '0.00'}`}
        />
        {!!premium_amenities_amount && (
          <DetailRow
            label="Premium amenities"
            value={`$${premium_amenities_amount?.toFixed(2)}`}
          />
        )}
        {!!credit && (
          <DetailRow
            label="Credit"
            value={`$${credit.toFixed(2)}`}
          />
        )}
        <DetailRow
          label="Grand total"
          value={`$${total?.toFixed(2) || '0.00'}`}
        />
      </div>
    </div>
  )
}

const SubscriptionTimelineSection = ({ subscriptionData = [] }) => {
  const historyData = subscriptionData?.history ||
    (Array.isArray(subscriptionData) ? subscriptionData : []);

  if (!historyData.length) return null;

  return (
    <div className="d-flex gap-3">
      <div className="bg-white px-1 rounded mt-4 w-100">
        <div className="container pt-3">
          <Heading text="Subscription Timeline" customClass="m-0 px-0 pt-0" />
          <div className="pb-2 bg-white rounded">
            {historyData.map((item, index) => (
              <div key={index} className="d-flex border-bottom py-2 flex-column" >
                <div className="d-flex justify-content-between" style={{ flexWrap: "wrap" }}>
                  <div className="mb-1">
                    <strong>Period start:</strong>{' '}
                    {moment(item.start_date).format(DATE_FORMATS.LONG)}
                  </div>
                  <div className="mb-1">
                    <strong>Period end:</strong>{' '}
                    {moment(item.end_date).format(DATE_FORMATS.LONG)}
                  </div>
                  <div className="mb-1 text-capitalize">
                    <strong>Subscription status: </strong> {' '}
                    {item.status || '-'}
                  </div>
                  <div className="mb-1">
                    <strong>Amount: </strong> {' '}
                    {item.reservation_amount.toFixed(2)}
                  </div>
                  <div className="mb-1">
                    <strong>Tax: </strong> {' '}
                    {item.tax.toFixed(2)}
                  </div>
                  <div className="mb-1">
                    <strong>Total: </strong> {' '}
                    ${item.total.toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const RefundedDataSection = ({ refundedData, payment_intent }) => {
  // Check if refundedData is undefined, null, empty array or empty object
  if (!refundedData ||
    (Array.isArray(refundedData) && refundedData.length === 0) ||
    (typeof refundedData === 'object' && Object.keys(refundedData).length === 0)) {
    return null;
  }

  let statusLabel = null;
  if (payment_intent?.payment_status === "refunded") {
    statusLabel = "Refunded";
  } else if (payment_intent?.payment_status === "partial_refunded") {
    statusLabel = "Partially Refunded";
  }

  return (
    <div className="d-flex gap-3">
      <div className="bg-white px-1 rounded mt-4 w-100">
        <div className="container pt-3">
          {/* Show status label if available */}
          {statusLabel && (
            <div className="d-flex justify-content-between">
              <Heading text="Refund Details" customClass="m-0 px-0 pt-0" />
              <div className="mb-2">
                <div
                  className={`${payment_intent?.payment_status === "refunded" ? "bg-warning" : "bg-info"} text-capitalize bg-opacity-10 px-2 py-1 rounded-2 d-inline-block`}
                  style={{
                    color: payment_intent?.payment_status === "refunded" ? "#856404" : "#6f42c1",
                    fontWeight: "500"
                  }}
                >
                  {statusLabel}
                </div>
              </div>
            </div>
          )}
          <div className="pb-2 bg-white rounded">
            <div className="d-flex border-bottom py-2 flex-column">
              <div className="d-flex justify-content-between" style={{ flexWrap: "wrap" }}>
                <div className="mb-1">
                  <strong>ID:</strong>{' '}
                  {refundedData.id || '-'}
                </div>
                <div className="mb-1">
                  <strong>Transaction ID:</strong>{' '}
                  {refundedData.transaction_id || '-'}
                </div>
                <div className="mb-1">
                  <strong>Created at:</strong>{' '}
                  {refundedData.created_at ? moment(refundedData.created_at).format(DATE_FORMATS.LONG) : '-'}
                </div>
                <div className="mb-1">
                  <strong>Amount: </strong>{' '}
                  ${refundedData.refund_amount || '0.00'}
                </div>
                <div className="mb-1">
                  <strong>Refund ID: </strong>{' '}
                  {refundedData.refund_id || '-'}
                </div>
                {refundedData.reason !== null && refundedData.reason !== undefined && (
                  <div className="mb-1">
                    <strong>Reason: </strong>{' '}
                    {refundedData.reason || '-'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


// Main component
const Detail = ({
  statusInfo,
  ownerInfo,
  paymentInfo,
  bookingInfo,
  aircraftInfo,
  subscriptionData = [],
  DYNAMIC_CHECK,
  cancel_at_end,
  premium_amenities_amount,
  refundedData
}) => {
  const order_status = statusInfo?.order_status
  const subscription_status = statusInfo?.subscription_status
  const check_in_without_format = bookingInfo?.check_in_without_format
  const check_out_without_format = bookingInfo?.check_out_without_format
  const rental_type = bookingInfo?.rental_type
  const payment_intent = paymentInfo?.payment_intent

  const params = {
    orderStatus: order_status,
    checkIn: check_in_without_format,
    checkOut: check_out_without_format,
    rentalType: rental_type,
    paymentStatus: payment_intent?.payment_status,
    subscriptionStatus: subscription_status,
    cancelAtEnd: cancel_at_end
  };

  const enhancedOwnerInfo = { ...ownerInfo, params, created_at: ownerInfo?.created_at };

  return (
    <div>
      <div className="d-flex gap-3">
        <ReservationDetailsSection
          bookingInfo={bookingInfo}
          statusInfo={statusInfo}
          aircraftInfo={aircraftInfo}
          ownerInfo={enhancedOwnerInfo}
          cancel_at_end={cancel_at_end}
        />

        <PaymentDetailsSection
          paymentInfo={paymentInfo}
          premium_amenities_amount={premium_amenities_amount}
          DYNAMIC_CHECK={DYNAMIC_CHECK}
        />
      </div>

      <RefundedDataSection refundedData={refundedData} payment_intent={payment_intent} />

      <SubscriptionTimelineSection subscriptionData={subscriptionData} />
    </div>
  )
}

export default memo(Detail)