import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

const TextEditJodit = ({
	value = "",
	onBlur,
	refer,
	maxChar = 2500,
	error,
	buttons = [],
	height = 300,
}) => {

	const localEditorRef = useRef(null);
	const editor = refer || localEditorRef;

	const config = useMemo(
		() => ({
			toolbarButtonSize: "large",
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false,
			enter: "P",
			defaultActionOnPaste: "insert_clear_html",
			readonly: false,
			placeholder: "Add Description",
			height,
			limitChars: maxChar || 2500,
			language: "en",
			showWordsCounter: false,
			showXPathInStatusbar: false,
			hidePoweredByJodit: true,
			buttons: buttons?.length ? buttons : [
				"bold",
				"italic",
				"underline",
				"ul",
				"ol",
				"fontsize",
				"paragraph",
				"align",
				"undo",
				"redo",
			],
			buttonsMD: buttons?.length ? buttons : [
				"bold",
				"italic",
				"underline",
				"ul",
				"ol",
				"undo",
				"redo",
			],
			buttonsSM: buttons?.length ? buttons : [
				"bold",
				"italic",
				"underline",
				"ul",
				"ol",
				"undo",
			],
			buttonsXS: buttons?.length ? buttons : [
				"bold",
				"italic",
				"underline",
				"ul",
				"undo",
			],
			cleanHTML: {
				denyTags: `script,pre,figure${buttons?.includes("link") ? "" : ",a"}`,
			},
			style: {
				color: '#000',
				paddingLeft: '1rem',
			},
			contentStyle: {
				color: '#000',
			},
		}),
		[maxChar, !!value, !!buttons?.length]
	);


	return (
		<div className="max-w-full">
			<JoditEditor
				ref={editor}
				value={value || ""}
				config={config}
				tabIndex={1}
				onBlur={(newContent) => onBlur(newContent)}

			/>
			<div
				className={`sm:w-1/2 mx-auto flex ${error ? "justify-between" : "justify-end"
					} my-2`}
			>
			</div>
		</div>
	);
};

export default TextEditJodit;
