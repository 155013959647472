import React from "react"

export function Spinner({ size = 12, r = 10, hT = "", wT = "", }) {
  return (
    <svg
      style={{ height: hT ? hT : "20px", width: wT ? wT : "20px" }}
      className="spinning"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx={size}
        cy={size}
        r={r}
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

const DefaultLoading = ({ style={}, className="" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
        backdropFilter: "blur(1px)",
        zIndex: "9999",
        ...style,
      }}
      className={className}
    >

      <Spinner />

    </div>
  )
}

export default DefaultLoading
