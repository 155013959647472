import React from "react"
import { Button, Container, Card, CardBody } from "reactstrap"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom"
import TableContainerPagination from "components/Common/TableContainerPagination"
import fetchPaginatedData from "utils/fetchPaginatedData"
import { toast } from "react-toastify"
import {
  OrderId,
  Title,
  Date,
  City,
  Status,
  NearAirport,
  Action,
} from "./LatestRequestsCol"

const HangarsApproved = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [tabIndex, setTabIndex] = useState(STATUS.HANGAR_APPROVED)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    canNextPage: false,
    canPreviousPage: false
  })

  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Hangar Requests | HangarDirect"

  const handleModelData = row => {
    history.push("/hangar-details/" + row.id)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Title",
        accessor: "Title",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Title {...cellProps} />
        },
      },
      {
        Header: "Created date",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "City",
        accessor: "City",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <City {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} tabIndex={tabIndex} />
        },
      },
      {
        Header: "Near airport",
        accessor: "NearAirport",
        disableFilters: true,
        Cell: cellProps => {
          return <NearAirport {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        Cell: cellProps =>
          tabIndex === 1 ? (
            <Action
              {...cellProps}
              refresh={getHangarsData}
              alert={alert}
              setAlert={setAlert}
              setLoading={setLoading}
            />
          ) : (
            <div>No actions to perform!</div>
          ),
      },
      {
        Header: "View details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => handleModelData(cellProps.row.original)}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    [tabIndex]
  )

  const getHangarsData = async (parameter) => {
    try {
      setLoading(true)
      let defaultFilters = [{ column: 'status', operator: 'eq', value: tabIndex }]

      if (parameter?.filter?.length) {
        const { data, count, error } = await supabase
          .from(TABLES.HANGARS)
          .select('*', { count: 'exact' })
          .eq('status', tabIndex)
          .or(
            `title.ilike.%${parameter.filter}%,city.ilike.%${parameter.filter}%`
          )
          .range(
            (parameter?.page - 1) * (parameter?.pageSize || 10),
            (parameter?.page * (parameter?.pageSize || 10)) - 1
          )
          .order('created_at', { ascending: false })

        if (error) {
          toast.error(error.message)
          return
        }

        if (data?.length) {
          setHangars(data)
          setPagination({
            currentPage: parameter?.page || 1,
            pageSize: parameter?.pageSize || 10,
            totalCount: count || 0,
            totalPages: Math.ceil((count || 0) / (parameter?.pageSize || 10)),
            canPreviousPage: (parameter?.page || 1) > 1,
            canNextPage: (parameter?.page || 1) < Math.ceil((count || 0) / (parameter?.pageSize || 10))
          })
        } else {
          setHangars([])
          setPagination({
            ...pagination,
            totalCount: 0,
            totalPages: 0,
            canNextPage: false,
            canPreviousPage: false
          })
        }
        setLoading(false)
        return
      }

      const result = await fetchPaginatedData({
        table: TABLES.HANGARS,
        select: '*',
        page: parameter?.page || pagination?.currentPage || 1,
        pageSize: parameter?.pageSize || pagination?.pageSize || 10,
        orderBy: 'created_at',
        ascending: false,
        filters: defaultFilters,
      })

      if (result.data?.length) {
        setHangars(result.data)
        setPagination({
          ...pagination,
          ...result.pagination,
          canPreviousPage: result.pagination.currentPage > 1,
          canNextPage: result.pagination.currentPage < result.pagination.totalPages
        })
      } else {
        setHangars([])
        setPagination({
          ...pagination,
          totalCount: 0,
          totalPages: 0,
          canNextPage: false,
          canPreviousPage: false
        })
      }
    } catch (error) {
      console.error("Error fetching hangars:", error.message)
      toast.error(error.message)
      setHangars([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getHangarsData()
  }, [tabIndex])

  return (
    <React.Fragment>
      <div className="page-content position-relative" style={{ minHeight: "400px" }}>
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}

          {loading && <DefaultLoading style={{ position: "absolute", width: "90%", height: "100%", minHeight: "400px" }} />}

          <div className="mb-4 h4 card-title">Hangar Requests</div>
          <Card>
            <CardBody>
              <TableContainerPagination
                columns={columns}
                data={hangars}
                isSortBy={false}
                isGlobalFilter={true}
                isAddUserList={false}
                isFilter={false}
                customPageSize={10}
                pagination={pagination}
                refetchData={getHangarsData}
                className="custom-header-css"
                rowClass="cursor-auto-imp"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HangarsApproved