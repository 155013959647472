import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import DefaultLoading from "common/loading"
import moment from "moment"
import { supabase } from "pages/Utility/supabase"
import Earning from "./earning"
import MiniWidget from "./mini-widget"
import SalesAnalytics from "./sales-analytics"
import { TABLES } from "pages/Utility/constants"


const DashboardSaas = props => {
  const [loading, setLoading] = useState(false)
  const [allData, setAllData] = useState([])
  const [transactionData, setTransactionData] = useState([])

  const [rentalTypePayment, setRentalTypePayment] = useState({
    monthly: 0,
    daily: 0,
  })

  const total = rentalTypePayment.monthly + rentalTypePayment.daily
  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Orders",
      value: allData.length,
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$" + total.toFixed(2),
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value:
        "$" +
        (total / transactionData.length >= 0
          ? total / transactionData.length
          : 0.0
        ).toFixed(2),
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ]

  useEffect(() => {
    getHangarsTransactionData()
  }, [])


  const getHangarsTransactionData = async () => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase.from(TABLES.TRANSACTIONS_NEW).select("*")
      if (error) {
        throw error
      }
      const rental = data.reduce(
        (acc, item) => {
          return {
            ...acc,
            [item.rental_type]: acc[item.rental_type] + +item.total,
          }
        },
        { daily: 0, monthly: 0 }
      )
      delete rental.null

      setRentalTypePayment(rental)
      const inDays = data?.reduce((acc, item) => {
        const { created_at, total } = item
        const date = moment(created_at).format("YYYY-MM-DD")
        const index = acc?.findIndex(i => i.date === date)

        if (index === -1) {
          acc.push({ date, Amount: +total })
        } else {
          acc[index].Amount += +total
        }
        return acc
      }, [])
      setTransactionData(inDays)
      setAllData(data)
    } catch (error) {
      console.log("error: ", error)
    }
    setLoading(false)
  }
  document.title = "Sales Analytics | HangarDirect"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <>
              <Row>
                <Col xl="12">
                  <Row>
                    <MiniWidget reports={reports} />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Earning dataColors='["--bs-primary"]' data={transactionData} />
                <SalesAnalytics
                  data={rentalTypePayment}
                  dataColors='["--bs-primary", "--bs-success", "--bs-danger"]'
                />
              </Row>
              <Row></Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default DashboardSaas
