import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Date = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const UserName = cell => {
  const row = cell.row.original;
  const fullName = row?.user_id?.first_name + " " + row?.user_id?.last_name;
  return fullName || "-"
}

const Email = cell => {
  return cell.value || "-"
}

const StripeId = cell => {
  return cell.cell.row.original.user_stripe_account
    ? cell.cell.row.original.user_stripe_account
    : ""
}

const Status = cell => {
  return (
    <Badge
      style={{ textTransform: "capitalize" }}
      className={
        "font-size-11 badge-soft-" +
        (cell?.row?.original.approved === "inReview" ? "warning" : "warning")
      }
    >
      {"in Review"}
    </Badge>
  )
}

const Amount = cell => {
  return cell.cell.row.original.amount
    ? "$" + (+cell.cell.row.original?.amount).toFixed(2)
    : ""
}

const Action = cell => {
  return null;
}

export { CheckBox, OrderId, Date, UserName, Email, StripeId, Status, Amount, Action }